import React from "react";

import styled from "styled-components";

import { Text } from "@earlybird/ui";

import CheckBox from "@components/atoms/CheckBox";

interface SettlementHistoryFilterCardProps {
  isSelected: boolean;
  name: string;
  onChange: (code: boolean) => void;
}

export const SettlementHistoryFilterCard: React.FC<
  SettlementHistoryFilterCardProps
> = ({ isSelected, name, onChange }) => {
  return (
    <FilterCardWrapper onClick={onChange}>
      <CheckBox checked={isSelected} onChange={onChange} />
      <Text bold>{name}</Text>
    </FilterCardWrapper>
  );
};

export default SettlementHistoryFilterCard;

const FilterCardWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 4px 0;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  background: transparent;
  gap: 3px;
`;
