import { Suspense, lazy, useEffect } from "react";

import {
  PlatformCodeTypes,
  PlatformStatusProps,
  ServerError,
  getErrorResponse,
  getFormattedDateWithDash,
  getMonth,
  getYear,
  modalCode,
} from "@earlypay/shared";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
  Box,
  PageContainer,
  Spinner,
  VStack,
  useConfirmModal,
  useToast,
} from "@earlybird/ui";

import { Meta } from "@components/commons/index";

import {
  useFetchDailyPlatformsSalesSummary,
  useFetchDailySales,
} from "@/apis/hooks";
import { useFetchPlatformAccountStatus } from "@/apis/hooks/platforms/queries";
import PlatformLinkList from "@/components/features/calendar/PlatformLinkList";
import SalesCalendar from "@/components/features/calendar/SalesCalendar";
import MainHeader from "@/components/layouts/MainHeader";
import { useCalendar } from "@/hooks/useCalendar";
import { applicationState } from "@/recoil/application/atom";

const LazyVocEarlypayBoard = lazy(
  () => import("@components/features/feedback/VocEarlypayBoard"),
);

const Calendar = () => {
  const application = useRecoilValue(applicationState);
  const { openConfirmModal } = useConfirmModal();
  const { addToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const newSearchParams = new URLSearchParams(location.search);
  const {
    selectedDate,
    currentMonth,
    currentYear,
    startDate,
    endDate,
    startOfCalendar,
    endOfCalendar,
  } = useCalendar();

  const formattedSelectedDate = getFormattedDateWithDash(selectedDate);

  const {
    data: sales,
    isFetching: isSalesFetching,
    isError,
    error,
  } = useFetchDailySales(application.store.id, {
    startDate: startOfCalendar,
    endDate: endOfCalendar,
  });
  const { data: platformSales, isFetching: isPlatformsSalesFetching } =
    useFetchDailyPlatformsSalesSummary(application.store.id, {
      date: formattedSelectedDate,
    });
  const { data: platformStatus, isFetching: isPlatformStatusFetching } =
    useFetchPlatformAccountStatus(application.store.id);

  const handleNavigateDate = (date: Date) => {
    newSearchParams.set("selectedDate", getFormattedDateWithDash(date));
    navigate(`${location.pathname}?${newSearchParams}`, { replace: true });
  };

  const handleNavigateMonth = (date: Date) => {
    newSearchParams.set("currentMonth", `${getMonth(date)}`);
    newSearchParams.set("currentYear", `${getYear(date)}`);
    navigate(`${location.pathname}?${newSearchParams}`);
  };

  const handleClickPlatformInfo = (
    isActive: boolean,
    isPending: boolean,
    platform: PlatformCodeTypes,
  ) => {
    if (isPending) {
      return openConfirmModal({ id: modalCode.NOTICE_COLLECTING_SALES });
    }

    if (isActive) {
      newSearchParams.set("platform", `${platform.toLowerCase()}`);
      newSearchParams.delete("currentMonth");
      newSearchParams.delete("currentYear");
      return navigate(`/calendar/detail?${newSearchParams}`);
    }

    return;
  };

  useEffect(() => {
    if (isError) {
      const errorResponse = getErrorResponse(error as ServerError);
      const code =
        errorResponse && "code" in errorResponse && errorResponse.code;
      code === "invalid-date-range" &&
        addToast("조회 가능한 기간이 아니에요.", "negative");
    }
  }, [isError]);

  return (
    <>
      <Meta title="얼리페이 | 캘린더" name={"캘린더"} />
      <MainHeader />

      <PageContainer
        padding={"20px 20px 73px 20px"}
        style={{ backgroundColor: "#F4F4F4" }}
      >
        <VStack spacing={6} padding={"0 0 48px 0"}>
          <CalendarWrapper>
            <VStack spacing={5}>
              <VStack>
                {/** 매출 내역 캘린더 */}
                <SalesCalendar
                  salesAmount={sales?.totalAmount || 0}
                  selectedDate={selectedDate}
                  sales={sales?.daily || []}
                  startDate={startDate}
                  endDate={endDate}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                  handleNavigateDate={handleNavigateDate}
                  handleNavigateMonth={handleNavigateMonth}
                  loading={isSalesFetching}
                />
                <Line />
              </VStack>

              {/** 플랫폼 별 연동 상태 및 매출 정보 */}
              <PlatformLinkList
                date={selectedDate}
                totalAmount={platformSales?.totalAmount || 0}
                platforms={platformSales?.platforms || []}
                platformStatus={platformStatus as PlatformStatusProps}
                loading={isPlatformStatusFetching || isPlatformsSalesFetching}
                handleClickPlatformInfo={handleClickPlatformInfo}
              />
            </VStack>
          </CalendarWrapper>

          {/** 얼리페이 VOC 수집 */}
          <Suspense
            fallback={
              <Box width={"100%"} height={174} center>
                <Spinner />
              </Box>
            }
          >
            <LazyVocEarlypayBoard category={"calendar"} />
          </Suspense>
        </VStack>
      </PageContainer>
    </>
  );
};

export default Calendar;

const CalendarWrapper = styled(Box).attrs(() => ({
  width: "100%",
  radius: "2xl",
  bg: "bg-primary",
  shadow: "2xl",
}))`
  border: 1px solid var(--earlybird-color-border-secondary);
`;

const Line = styled.hr`
  width: 100%;
  height: 1px;
  background: var(--earlybird-color-border-secondary);
  border: none;
`;
