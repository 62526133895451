/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from "react";

import { trackEventMixpanel } from "@earlypay/shared/configs";

import { ChevronDownIcon } from "@earlybird/icons";
import { Icon, Text } from "@earlybird/ui";

import {
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionInnerContent,
  AccordionItem,
  AccordionWrapper,
} from "./index.styles";

export interface AccordionProps {
  label: string;
  isInitiallyOpened?: boolean;
  controlledAccordion?: {
    value: boolean;
    onChangeHandler: React.Dispatch<React.SetStateAction<boolean>>;
  };
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

const Accordion = ({
  label,
  isInitiallyOpened,
  controlledAccordion,
  children,
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(false),
    duration = 200,
    accordionContentRef = useRef(null),
    savedControlledAccordionChangeListener: React.MutableRefObject<any> =
      useRef();

  useEffect(() => {
    if (controlledAccordion?.onChangeHandler) {
      savedControlledAccordionChangeListener.current =
        controlledAccordion.onChangeHandler;
    }
  }, [controlledAccordion?.onChangeHandler]);

  const incrementHeight = (progress: number) => {
    if (accordionContentRef.current) {
      const element: HTMLElement = accordionContentRef.current,
        sectionHeight = progress * element.scrollHeight;

      element.style.height = `${sectionHeight}px`;
    }
  };

  const decrementHeight = (progress: number) => {
    if (accordionContentRef.current) {
      const element: HTMLElement = accordionContentRef.current,
        sectionHeight = element.scrollHeight - progress * element.scrollHeight;

      element.style.height = `${
        sectionHeight > element.scrollHeight
          ? element.scrollHeight
          : sectionHeight
      }px`;
      element.style.overflow = "hidden";
    }
  };

  const expandAccordion = () => {
    const start = performance.now();

    if (accordionContentRef.current) {
      const element: HTMLElement = accordionContentRef.current;

      requestAnimationFrame(function animate(time) {
        const runtime = time - start,
          relativeProgress = runtime / duration,
          process = Math.min(relativeProgress, 1);

        if (process < 1) {
          incrementHeight(process);
          requestAnimationFrame(animate);
        }

        if (process === 1) {
          element.style.height = "auto";
          element.style.overflow = "initial";
        }
      });
    }
  };

  const collapseAccordion = () => {
    const start = performance.now();

    if (accordionContentRef.current) {
      const element: HTMLElement = accordionContentRef.current;

      requestAnimationFrame(function animate(time) {
        const runtime = time - start,
          relativeProgress = runtime / duration,
          process = Math.min(relativeProgress, 1);

        if (process < 1) {
          decrementHeight(process);
          requestAnimationFrame(animate);
        }
        if (process === 1) {
          element.style.height = "";
          element.style.overflow = "";
        }
      });
    }
  };

  const updateUi = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        expandAccordion();
      } else {
        collapseAccordion();
      }
    },
    [expandAccordion, collapseAccordion],
  );

  const toggleAccordion = useCallback(
    (label?: string) => {
      const expanded = !isExpanded;

      trackEventMixpanel("click_FAQ_content", {
        description: "랜딩페이지 내 FAQ 컨텐츠 클릭",
        title: label,
      });

      updateUi(expanded);
      setIsExpanded(expanded);
    },
    [isExpanded, updateUi],
  );

  const toggleAccordionFromOutside = useCallback(() => {
    if (
      controlledAccordion?.value !== undefined &&
      savedControlledAccordionChangeListener.current
    ) {
      const expanded = !controlledAccordion.value;

      savedControlledAccordionChangeListener.current(expanded);
    }
  }, [controlledAccordion?.value]);

  useEffect(() => {
    //update UI if controlled from the outside
    if (controlledAccordion?.value !== undefined) {
      updateUi(controlledAccordion.value);
    }
  }, [updateUi, controlledAccordion?.value]);

  useEffect(() => {
    //open accordion if default is open and not controlled from the outside
    if (isInitiallyOpened && controlledAccordion?.value === undefined) {
      expandAccordion();
      setIsExpanded(true);
    }
  }, [isInitiallyOpened, expandAccordion, controlledAccordion?.value]);

  return (
    <AccordionWrapper
      className={`${
        isExpanded || controlledAccordion?.value
          ? "accordion-expanded"
          : "accordion-collapsed"
      }`}
    >
      <AccordionItem>
        <AccordionHeader
          onClick={
            controlledAccordion !== undefined
              ? toggleAccordionFromOutside
              : () => toggleAccordion(label)
          }
        >
          <Text className="accordion-title">{label}</Text>
          <AccordionIcon className="accordion-header-icon">
            <Icon icon={ChevronDownIcon} />
          </AccordionIcon>
        </AccordionHeader>
        <AccordionContent ref={accordionContentRef}>
          <AccordionInnerContent>{children}</AccordionInnerContent>
        </AccordionContent>
      </AccordionItem>
    </AccordionWrapper>
  );
};

export default Accordion;
