import { Text } from "@earlybird/ui";

import { FastCalculateWrapper, TitleStack } from "./index.styles";

const FastCalculate = () => {
  return (
    <FastCalculateWrapper>
      <TitleStack>
        <Text
          className={"earlypay-landing-title"}
          as="h1"
          typo={"title-1"}
          bold
        >
          최대 14일 걸리던 매출 정산
        </Text>
        <Text
          className={"earlypay-landing-title"}
          as="h1"
          typo={"title-1"}
          bold
          color={"content-highlight"}
        >
          단 하루 만에
        </Text>
      </TitleStack>
      <Text color={"content-secondary"} center as="h4">
        최대 14일 걸리던 배달 매출을 단 하루 만에
        <br />
        최대 93% 빠르게 입금
      </Text>
    </FastCalculateWrapper>
  );
};

export default FastCalculate;
