import React from "react";

import {
  PlatformSalesTagsCode,
  PlatformSalesTypes,
} from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";

import { changeNumberToMoney } from "@utils/types";

import {
  changeSettlementCodeToImage,
  getSalesDateTime,
} from "../../SettlementAmountList/utils";
import HistoryAmountTag from "./item.tag";

export interface SalesAmountCardItem {
  items?: PlatformSalesTypes;
  isLoading: boolean;
}

export const HistoryAmountItem: React.FC<SalesAmountCardItem> = ({
  items,
  isLoading,
}) => {
  // 로딩 상태일 경우, 스켈레톤을 반환합니다.
  if (isLoading || !items) {
    return (
      <Wrapper>
        <InnerWrapper>
          <TitleWrapper>
            <Skeleton containerClassName="flex-1" width={44} height={44} />
            <TitleInnerWrapper>
              <Skeleton containerClassName="flex-1" width={100} height={18} />
              <Skeleton containerClassName="flex-1" width={90} height={12} />
            </TitleInnerWrapper>
          </TitleWrapper>
          <AmountWrapper>
            <Skeleton containerClassName="flex-1" width={100} height={18} />
            <Skeleton containerClassName="flex-1" width={160} height={12} />
          </AmountWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }

  const platformImage = changeSettlementCodeToImage(items.depositor);
  const date = getSalesDateTime(items.soldAt);
  const totalAmount = changeNumberToMoney(items.totalAmount);
  const commissionAmount = changeNumberToMoney(items.commissionAmount);
  const salesAmount = changeNumberToMoney(items.salesAmount);

  const hasTag = Boolean(items.tags.length);
  const isCanceled = items.tags.includes(PlatformSalesTagsCode.CANCEL);

  return (
    <Wrapper hasTag={hasTag}>
      <InnerWrapper>
        <TitleWrapper>
          <CustomImage tag={platformImage} width={44} height={44} />
          <TitleInnerWrapper>
            <Text bold>{items.name}</Text>
            <Text typo={"caption-1"} color={"content-secondary"}>
              {date}
            </Text>
          </TitleInnerWrapper>
        </TitleWrapper>

        <AmountWrapper>
          <Amount bold isCanceled={isCanceled}>
            {totalAmount}
          </Amount>
          <AmountInnerWrapper>
            <Text typo={"caption-1"} color={"content-secondary"}>
              {`결제액 ${salesAmount}`}
            </Text>
            <Divider />
            <Text typo={"caption-1"} color={"content-secondary"}>
              {`수수료 ${commissionAmount}`}
            </Text>
          </AmountInnerWrapper>
        </AmountWrapper>
      </InnerWrapper>
      {hasTag && (
        <TagSection>
          {items.tags.map((type, index) => (
            <HistoryAmountTag key={index} type={type} />
          ))}
        </TagSection>
      )}
    </Wrapper>
  );
};

export default HistoryAmountItem;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${({ hasTag }: { hasTag: boolean }) => (hasTag ? "4px" : 0)};
  padding: 12px 0;
`;

const TagSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: default;
`;

const CustomImage = styled(Image)`
  width: 44px;
  height: 44px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  border: ${({ tag }: { tag: string }) =>
    tag === ImageTypes.SETTLEMENT_COUPANG_EATS ? "1px solid #F4F4F4" : "none"};
`;

const TitleInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const AmountWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`;

const AmountInnerWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 6px;
  align-items: center;
`;

const Divider = styled.div`
  width: 1px;
  height: 12px;
  background: #c6c6c6;
`;

const Amount = styled(Text)`
  text-decoration: ${({ isCanceled }: { isCanceled: boolean }) =>
    isCanceled && "line-through"};
  text-decoration-thickness: 2px;
`;
