import { useEffect } from "react";

import { trackPageViewMixpanel } from "@earlypay/shared";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

interface MetaPropsType {
  title?: string;
  name?: string;
  options?: any;
}

export const Meta = ({ title, name, options }: MetaPropsType) => {
  const location = useLocation();

  useEffect(() => {
    trackPageViewMixpanel(name, options);
  }, [location]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default Meta;
