import { Text } from "@earlybird/ui";

import Stack from "@components/atoms/Stack";
import { StackDirectionsTypes } from "@components/atoms/Stack/type";

import {
  CarouselItem,
  CarouselTrack,
  ReviewCardCarouselWrapper,
} from "./index.styles";
import { messageDummyData } from "./messageDummyData.mock";

export const ReviewCardCarousel = () => {
  return (
    <ReviewCardCarouselWrapper>
      <CarouselTrack>
        {[
          ...Object.keys(messageDummyData),
          ...Object.keys(messageDummyData),
        ].map((detailKey, index) => {
          const name = messageDummyData[detailKey].name;
          const region = messageDummyData[detailKey].region;

          return (
            <CarouselItem key={index}>
              <Text>{messageDummyData[detailKey].content}</Text>
              <Stack direction={StackDirectionsTypes.ROW}>
                <Text weight="SEMI_BOLD">{`- ${region}` + " "}</Text>
                <Text weight="SEMI_BOLD" color="CONTENT_HIGHLIGHT">
                  {name}
                </Text>
                <Text weight="SEMI_BOLD">{" " + `사장님 -`}</Text>
              </Stack>
            </CarouselItem>
          );
        })}
      </CarouselTrack>
    </ReviewCardCarouselWrapper>
  );
};
