import { useState } from "react";

import { trackEventMixpanel } from "@earlypay/shared";

import { Text } from "@earlybird/ui";

import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";
import Stack from "@components/atoms/Stack";
import { StackDirectionsTypes } from "@components/atoms/Stack/type";

import {
  FeatureContentContainer,
  FeatureContentStack,
  FeatureContentWrapper,
  FeatureTabStack,
  FeatureWrapper,
  TabTitle,
} from "./index.styled";

export interface FeatureTabComponentProps {
  disabled: boolean;
  title: string;
  subTitle: string;
  content: string[];
  subContent?: string;
  imageTag: ImageTypes;
  alt: string;
}

const FeatureContent = ({
  disabled,
  title,
  subTitle,
  content,
  subContent,
  imageTag,
  alt,
}: FeatureTabComponentProps) => {
  return (
    <FeatureContentStack disabled={disabled}>
      <FeatureContentContainer>
        <Stack>
          <Text className="earlypay-feature-subtitle" typo={"title-2"} bold>
            {title}
          </Text>
          <Text
            className="earlypay-feature-subtitle"
            typo={"title-2"}
            bold
            color="content-highlight"
          >
            {subTitle}
          </Text>
        </Stack>
        <div className="earlypay-feature-image-mobile">
          <Image tag={imageTag} alt={alt} width={200} height={200} />
        </div>

        <Text className={"earlypay-feature-text-wrapper"}>
          {content.map((item, index) => (
            <Text
              key={index}
              className="earlypay-feature-text"
              color={"content-secondary"}
            >
              {item}
            </Text>
          ))}
        </Text>
        {subContent && (
          <Text
            typo="caption-1"
            color="content-tertiary"
            className="earlypay-feature-caption"
            as="h6"
          >
            {subContent}
          </Text>
        )}
      </FeatureContentContainer>
      <div className="earlypay-feature-image">
        <Image tag={imageTag} alt={alt} width={300} height={300} />
      </div>
    </FeatureContentStack>
  );
};

const FeatureSection = () => {
  const [tabTitle, setTabTitle] = useState<string>("선정산");

  const handleClickTab = (title: string) => {
    trackEventMixpanel("click_feature_tab", {
      description: "랜딩페이지 내 feature 탭 클릭",
      title: title,
    });
    setTabTitle(title);
  };

  return (
    <FeatureWrapper>
      <Stack direction={StackDirectionsTypes.ROW}>
        <Text
          className="earlypay-feature-title"
          typo={"title-1"}
          color={"content-highlight"}
          bold
        >
          사장님
        </Text>
        <Text className="earlypay-feature-title" typo={"title-1"} bold>
          이시면 누릴 수 있어요
        </Text>
      </Stack>

      <FeatureTabStack>
        {["선정산", "매출리포트", "간편가입", "책임보험"].map((item, index) => (
          <TabTitle key={index} onClick={() => handleClickTab(item)}>
            <Text
              className="feature-tab-title"
              as="h4"
              color={tabTitle === item ? "content-primary" : "content-tertiary"}
              underline={tabTitle === item}
            >
              {item}
            </Text>
          </TabTitle>
        ))}
      </FeatureTabStack>

      <FeatureContentWrapper>
        <FeatureContent
          alt="주말, 평일 상관없이 365일 선정산"
          disabled={tabTitle !== "선정산"}
          title="빨간 날도 평일도"
          subTitle="365일 선정산"
          content={[
            "얼리페이는 어제 발생한 배달 앱 매출* 및 카드 매출\n모두 365일 휴일 상관없이 입금됩니다.",
          ]}
          subContent="*2023.11월 기준 배달의민족, 요기요 가능"
          imageTag={ImageTypes.LANDING_FEATURE_1}
        />
        <FeatureContent
          alt="얼리페이에서 배달 및 카드 매출을 알림톡과 리포트로 한눈에 확인"
          disabled={tabTitle !== "매출리포트"}
          title="배달, 카드"
          subTitle="매출을 한 눈에"
          content={[
            "얼리페이에서 배달 및 카드 매출을 알림톡과 리포트로\n한 눈에 확인해 보세요.",
          ]}
          imageTag={ImageTypes.LANDING_FEATURE_2}
        />
        <FeatureContent
          alt="불리한 약정이나 중도 해지 위약금 없이 누구나 가입 후 이용 가능"
          disabled={tabTitle !== "간편가입"}
          title="불리한 약정"
          subTitle="위약금 없이"
          content={[
            "환금성, 유흥업종이 아니라면 누구나 가입 신청이 가능해요.\n",
            "언제든지 자유롭게 가입하고 해지하세요.",
          ]}
          subContent="단, 당사 심사 기준에 따사 가입이 거절되거나 서비스가 제한될 수 있습니다."
          imageTag={ImageTypes.LANDING_FEATURE_3}
        />
        <FeatureContent
          alt="5만원 상당의 한화손해보험 시설, 음식물 보험 무료 지원"
          disabled={tabTitle !== "책임보험"}
          title="혹시 모를 문제에도"
          subTitle="대처할 수 있도록"
          content={[
            "5만원 상당의 한화손해보험\n",
            "시설, 음식물 보험을 무료 지원해 드립니다.",
          ]}
          imageTag={ImageTypes.LANDING_FEATURE_4}
        />
      </FeatureContentWrapper>
    </FeatureWrapper>
  );
};

export default FeatureSection;
