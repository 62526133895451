import { useEffect } from "react";

import { useLogin } from "@hooks/useLogin";

export const Logout = () => {
  const { handleLogout } = useLogin();

  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};

export default Logout;
