import React from "react";

import { applicationState } from "@recoil/application/atom";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

import Image from "@components/atoms/Image";
import { ImageTypes } from "@components/atoms/Image/type";

import { EmotionTypes, FeedbackCategoryTypes } from "@/pages/my/Feedback";
import { interactionTransparentLight } from "@/styles/interactions";

import { titleContents } from "./contents.map";

interface VocEarlyBoardProps {
  category?: FeedbackCategoryTypes;
}

export const VocEarlypayBoard = ({ category = "home" }: VocEarlyBoardProps) => {
  const application = useRecoilValue(applicationState);
  const navigate = useNavigate();

  const onClickEmotionLink = (
    event: React.MouseEvent,
    emotion: EmotionTypes,
  ) => {
    event.stopPropagation();
    navigate(`/my/feedback?emotion=${emotion}&category=${category}`);
  };

  return (
    <VocWrapper>
      <TitleWrapper>
        <Text typo={"body-1"} center bold>
          {application.user.name} {titleContents[category].title}
        </Text>
      </TitleWrapper>
      <EmotionWrapper>
        <Emotion
          onClick={(event: React.MouseEvent) =>
            onClickEmotionLink(event, "unsatisfying")
          }
        >
          <Image
            tag={ImageTypes.UNSATISFYING}
            width={40}
            height={40}
            alt="UNSATISFYING"
          />
          <Text typo={"body-3"} color="content-secondary" center>
            불만족
          </Text>
        </Emotion>
        <Emotion
          onClick={(event: React.MouseEvent) =>
            onClickEmotionLink(event, "soso")
          }
        >
          <Image tag={ImageTypes.SOSO} width={40} height={40} alt="SOSO" />
          <Text typo={"body-3"} color="content-secondary" center>
            보통
          </Text>
        </Emotion>
        <Emotion
          onClick={(event: React.MouseEvent) =>
            onClickEmotionLink(event, "satisfying")
          }
        >
          <Image
            tag={ImageTypes.SATISFYING}
            width={40}
            height={40}
            alt="SATISFYING"
          />
          <Text typo={"body-3"} color="content-secondary" center>
            만족
          </Text>
        </Emotion>
      </EmotionWrapper>
    </VocWrapper>
  );
};

export default VocEarlypayBoard;

const VocWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fefefe;
  padding: 16px 20px;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid var(--earlybird-color-border-secondary);
`;

const EmotionWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const Emotion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px 12px 4px;
  cursor: pointer;

  ${interactionTransparentLight}
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
