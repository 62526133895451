import Terms from "@pages/Terms";
import { Admin } from "@pages/admin";
import { Forbidden, NotFound, SystemError } from "@pages/errors";

import PublicRouteGuard from "../guards/PublicRouteGuard";

export const publicRouters = {
  path: "/",
  errorElement: <SystemError />,
  element: <PublicRouteGuard />,
  children: [
    {
      path: "/terms",
      children: [
        {
          path: "EP02",
          Component: Terms,
        },
        {
          path: "EP03",
          Component: Terms,
        },
      ],
    },
    {
      path: "/admin",
      Component: Admin,
    },
    // error
    { path: "404", Component: NotFound },
    { path: "403", Component: Forbidden },
    { path: "500", Component: SystemError },
    { path: "*", Component: NotFound },
  ],
};
