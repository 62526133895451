export const titleContents = {
  home: {
    title: "사장님!\n새로워진 얼리페이 홈 어떠신가요?",
  },
  settlement: {
    title: "사장님!\n사용하고 계신 정산내역 어떠신가요?",
  },
  calendar: {
    title: "사장님!\n새로워진 캘린더 어떠신가요?",
  },
};
