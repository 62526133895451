import { TERMS_PRIVACY_POLICY } from "@earlypay/shared";

import { EarlypayLogoIcon } from "@earlybird/icons";
import { Icon, Text } from "@earlybird/ui";

import {
  FooterContentStack,
  FooterInfoStack,
  FooterStack,
  FooterTermStack,
  FooterWrapper,
  TermLink,
} from "./index.styles";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterStack>
        <FooterContentStack>
          <Icon
            icon={EarlypayLogoIcon}
            color={"content-tertiary"}
            style={{ width: 90, height: 16 }}
          />
          <Text typo="body-3" bold color="content-tertiary">
            (주)얼리페이
          </Text>
        </FooterContentStack>

        <FooterInfoStack>
          <Text typo={"caption-1"} bold color="content-tertiary">
            대표자: 장환성 | 사업자 등록번호: 389-86-02190 | 주소:서울특별시
            마포구 마포대로 122, 12층
          </Text>
          <Text typo="caption-1" bold color="content-tertiary">
            고객센터: 1522-6911 | 카카오채널: @earlypay
          </Text>
        </FooterInfoStack>

        <FooterTermStack>
          <TermLink href={`/terms/EP02`}>
            <Text typo="caption-1" underline color="content-tertiary">
              서비스이용약관
            </Text>
          </TermLink>
          <TermLink href={TERMS_PRIVACY_POLICY}>
            <Text typo="caption-1" underline color="content-tertiary" bold>
              개인정보처리방침
            </Text>
          </TermLink>
        </FooterTermStack>
      </FooterStack>
    </FooterWrapper>
  );
};

export default Footer;
