import { useState } from "react";

import { trackEventMixpanel } from "@earlypay/shared/configs";
import { debounce } from "@earlypay/shared/utils";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { WonInCircleSolidIcon } from "@earlybird/icons";
import { Box, HStack, Icon, Text, VStack } from "@earlybird/ui";

import { useFetchStoreInfo } from "@apis/hooks";

interface StoreHistory {
  amount: number;
  date: string;
  isDeposited: boolean;
}

interface CardConnects {
  depositor: string;
  firstConnectedAt: string | null;
  isConnected: boolean;
  isDisabled: boolean;
}

interface StoreStatusType {
  history: {
    amount: number;
    date: string;
    isDeposited: boolean;
  }[];
  latest: {
    results: {
      amount: number;
      date: string;
      isDeposited: boolean;
    }[];
    status: "CHECKING_SALES" | "WAITING_FOR_FIRST_DEPOSIT" | "SENT";
  };
  scrapingProgress: {
    baemin: boolean;
    card: boolean;
    coupang: boolean;
    yogiyo: boolean;
  };
}

const TerminationType = ["TC", "T"];
const StopType = ["P", "PC", "TP"];

const TodayDepositsCard = ({
  storeId,
  storeStatus,
  storeStatusLoading,
}: {
  storeId: number;
  storeStatus: StoreStatusType;
  storeStatusLoading: boolean;
}) => {
  const findDate = ({ dayOffset }: { dayOffset: number }) => {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const paramDate = `${year}-${month}-${day}`;

    return paramDate;
  };

  const { data: storeInfo, isLoading: storeInfoLoading } =
    useFetchStoreInfo(storeId);

  const reversedHistory = storeStatus?.history?.slice().reverse();

  const today = findDate({ dayOffset: 0 });

  const [selectedDate, setSelectedDate] = useState<string>(today);

  const handleClickButton = debounce(
    async ({
      label,
      status,
      onClick,
    }: {
      label: string;
      status: string;
      onClick: () => void;
    }) => {
      onClick && onClick();
      trackEventMixpanel("click_button", {
        label: label,
        "settlement status": status,
      });
    },
    300,
  );

  const replaceDate = (date: string) => {
    const [_, month, day] = date.split("-");

    if (new Date(date).getDate() === new Date().getDate()) {
      return "오늘";
    }

    if (new Date(date).getDate() === new Date().getDate() - 1) {
      return "어제";
    }

    if (new Date(date).getDate() === new Date().getDate() - 2) {
      return "그저께";
    }

    return `${month}.${day}`;
  };

  const beforeFirstDeposit = storeInfo?.serviceInfo.cardConnects.filter(
    (item: CardConnects) => item.depositor !== "ETC",
  );
  const CUSTOM_WATING_FOR_FIRST_DEPOSIT =
    storeInfo?.serviceInfo.status === "A" &&
    beforeFirstDeposit?.every(
      (item: CardConnects) => item.isConnected === false,
    );

  const todayAmount = (
    type: "WAITING_FOR_FIRST_DEPOSIT" | "CHECKING_SALES" | "SENT",
    isDeposited: boolean,
    amount?: number,
    serviceType?: string,
  ) => {
    if (TerminationType.includes(serviceType as string) && !isDeposited) {
      return "해지된 상태예요";
    }
    if (StopType.includes(serviceType as string) && !isDeposited) {
      return "일시 정지된 상태예요";
    }
    if (
      type === "WAITING_FOR_FIRST_DEPOSIT" ||
      CUSTOM_WATING_FOR_FIRST_DEPOSIT
    ) {
      return "첫 입금을 기다리고 있어요";
    } else if (type === "CHECKING_SALES") {
      return "매출을 확인하고 있어요";
    } else {
      return amount?.toLocaleString() + "원";
    }
  };

  const beforeAmount = (
    storeHistory: StoreHistory[],
    serviceType?: string,
    timeLineStart?: string,
    timeLineEnd?: string,
  ) => {
    const selectedDateObj = selectedDate ? new Date(selectedDate) : null;
    const timeLineStartObj = timeLineStart ? new Date(timeLineStart) : null;
    const timeLineEndObj = timeLineEnd ? new Date(timeLineEnd) : null;
    const isDeposited = storeHistory?.find(
      item => item.date === selectedDate,
    ).isDeposited;

    const isWithinTimeLine =
      selectedDateObj &&
      timeLineStartObj &&
      selectedDateObj >= timeLineStartObj &&
      (!timeLineEndObj || selectedDateObj <= timeLineEndObj);

    if (
      TerminationType.includes(serviceType as string) &&
      isWithinTimeLine &&
      !isDeposited
    ) {
      return "해지된 상태예요";
    }
    if (
      StopType.includes(serviceType as string) &&
      isWithinTimeLine &&
      !isDeposited
    ) {
      return "일시 정지된 상태예요";
    }
    return (
      storeHistory
        .find(item => item.date === selectedDate)
        ?.amount?.toLocaleString() + "원"
    );
  };

  if (storeStatusLoading || storeInfoLoading)
    return (
      <Box
        width="100%"
        radius={"xl"}
        bg="bg-primary"
        padding="16px"
        style={{ border: "1px solid #EAEAEA" }}
      >
        <VStack spacing={7}>
          <Skeleton width={48} height={32} style={{ borderRadius: "999px" }} />
          <VStack spacing={3}>
            <Skeleton width={84} height={24} />
            <Skeleton width={218} height={32} />
          </VStack>
          <Skeleton width={424} height={46} />
        </VStack>
      </Box>
    );

  return (
    <Box
      radius={"xl"}
      width={"100%"}
      padding={16}
      bg="bg-primary"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <VStack spacing={7}>
        <HStack spacing={3}>
          {storeStatus?.latest && (
            <DatePickerButton
              className="active"
              isToday={selectedDate === today}
              onClick={() =>
                handleClickButton({
                  label: "오늘",
                  status: storeStatus?.latest?.status,
                  onClick: () => setSelectedDate(today),
                })
              }
            >
              <Text
                color={
                  selectedDate === today
                    ? "content-on-color"
                    : "content-secondary"
                }
                typo="body-3"
                weight="semi-bold"
              >
                오늘
              </Text>
            </DatePickerButton>
          )}
          {reversedHistory.map(
            (item: { amount: number; date: string; isDeposited: boolean }) => (
              <DatePickerButton
                key={`${item.date}_${item.amount}`}
                className="active"
                isToday={selectedDate === item.date}
                onClick={() =>
                  handleClickButton({
                    label: replaceDate(item.date),
                    status: item.isDeposited
                      ? "SENT"
                      : "WAITING_FOR_FIRST_DEPOSIT",
                    onClick: () => setSelectedDate(item.date),
                  })
                }
              >
                <Text
                  color={
                    selectedDate === item.date
                      ? "content-on-color"
                      : "content-secondary"
                  }
                  typo="body-3"
                  weight="semi-bold"
                >
                  {replaceDate(item.date)}
                </Text>
              </DatePickerButton>
            ),
          )}
        </HStack>
        <VStack spacing={3}>
          <HStack spacing={2} align="center">
            <Icon
              icon={WonInCircleSolidIcon}
              color="content-highlight"
              size={"md"}
            />
            <Text typo={"body-2"} color={"content-primary"} weight="semi-bold">
              선정산금
            </Text>
          </HStack>
          <Text typo="subtitle-1" weight="semi-bold" color="content-primary">
            {selectedDate === today
              ? todayAmount(
                  storeStatus?.latest?.status,
                  storeStatus?.latest?.results[0]?.isDeposited,
                  //storeStatus?.latest?.results[0]?.amount,
                  storeStatus?.latest?.results
                    .filter(item => item.isDeposited)
                    .reduce((sum, item) => sum + item.amount, 0),
                  storeInfo?.serviceInfo.status,
                )
              : beforeAmount(
                  storeStatus?.history,
                  storeInfo?.serviceInfo.status,
                  storeInfo?.serviceInfo?.statusTimeline.since,
                  storeInfo?.serviceInfo?.statusTimeline.until,
                )}
          </Text>
        </VStack>
        <ExtraInfo
          serviceStatus={storeInfo?.serviceInfo.status}
          selectedDate={selectedDate}
          today={today}
          storeStatus={storeStatus}
          isCustomWaitingForFirstDeposit={CUSTOM_WATING_FOR_FIRST_DEPOSIT}
        />
      </VStack>
    </Box>
  );
};

const ExtraInfo = ({
  serviceStatus,
  selectedDate,
  today,
  storeStatus,
  isCustomWaitingForFirstDeposit,
}: {
  serviceStatus: string;
  selectedDate: string;
  today: string;
  storeStatus: any;
  isCustomWaitingForFirstDeposit: boolean;
}) => {
  const navigate = useNavigate();

  const canShowDepositDetail = () => {
    const beforeAmount = storeStatus?.history.find(
      (item: StoreHistory) => item.date === selectedDate,
    )?.amount;
    if (selectedDate === today) {
      const todayAmount = storeStatus?.latest?.results
        .filter(
          (item: StoreHistory) =>
            item.isDeposited && item.date === selectedDate,
        )
        .reduce((sum: number, item: StoreHistory) => sum + item.amount, 0);
      return todayAmount > 0;
    }
    return beforeAmount > 0;
  };

  const handleClickButton = debounce(
    async ({
      label,
      status,
      onClick,
    }: {
      label: string;
      status: string;
      onClick: () => void;
    }) => {
      trackEventMixpanel("click_button", {
        label: label,
        "settlement status": status,
      });
      onClick && onClick();
    },
    300,
  );

  if (TerminationType.includes(serviceStatus) && !canShowDepositDetail()) {
    return (
      <Box
        padding="12px"
        bg="bg-informative-light"
        radius="xl"
        width="100%"
        style={{
          textAlign: "left",
        }}
      >
        <Text color="content-highlight" typo="body-3" weight="semi-bold">
          선정산 서비스 재개를 원하시면 고객센터로 문의해 주세요.
        </Text>
      </Box>
    );
  }

  const extraComment = () => {
    if (
      storeStatus?.latest?.status === "WAITING_FOR_FIRST_DEPOSIT" ||
      isCustomWaitingForFirstDeposit
    ) {
      return "회수계좌로 정산금이 처음 입금된 다음날부터 선정산이 시작돼요.";
    }
    if (storeStatus?.latest?.status === "CHECKING_SALES") {
      return "1시 전후로 입금될 예정이니 잠시만 기다려주세요!";
    }
    if (storeStatus?.latest?.status === "SENT") {
      return "상세내역 보러가기";
    }
  };

  if (StopType.includes(serviceStatus) && !canShowDepositDetail()) return null;

  return (
    <>
      {selectedDate === today ? (
        <DetailButton
          className={storeStatus?.latest?.status === "SENT" ? "active" : ""}
          padding="12px"
          bg={
            storeStatus?.latest?.status === "SENT"
              ? "bg-cta-tertiary"
              : "bg-informative-light"
          }
          radius={storeStatus?.latest?.status === "SENT" ? "lg" : "xl"}
          width="100%"
          style={{
            textAlign:
              storeStatus?.latest?.status === "SENT" ? "center" : "left",
            cursor: storeStatus?.latest?.status === "SENT" ? "pointer" : "auto",
          }}
          onClick={() =>
            storeStatus?.latest?.status === "SENT" &&
            handleClickButton({
              label: "상세내역 보러가기",
              status: "SENT",
              onClick: () =>
                navigate(
                  `/settlement?startDate=${selectedDate}&endDate=${selectedDate}`,
                ),
            })
          }
        >
          <Text color="content-highlight" typo="body-3" weight="semi-bold">
            {extraComment()}
          </Text>
        </DetailButton>
      ) : (
        <Box
          padding="12px"
          bg="bg-cta-tertiary"
          radius="lg"
          width="100%"
          style={{
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(
              `/settlement?startDate=${selectedDate}&endDate=${selectedDate}`,
            )
          }
        >
          <Text color="content-highlight" typo="body-3" weight="semi-bold">
            상세내역 보러가기
          </Text>
        </Box>
      )}
    </>
  );
};

export default TodayDepositsCard;

const DatePickerButton = styled.div`
  background-color: ${(props: { isToday: boolean }) =>
    props.isToday
      ? "var(--earlybird-color-bg-always-black)"
      : "var(--earlybird-color-bg-secondary)"};
  border-radius: var(--earlybird-radius-circle);
  cursor: pointer;
  padding: 6px 12px;
  display: flex;

  &:where(.active) {
    cursor: pointer;
    border-radius: var(--earlybird-radius-circle);

    &:active {
      background-color: var(--earlybird-color-bg-pressed);
      scale: 96%;
      transition: all 0.2s ease-out;
      border-radius: var(--earlybird-radius-circle);
    }
  }
`;

const DetailButton = styled(Box)`
  &:where(.active) {
    cursor: pointer;

    &:active {
      background-color: var(--earlybird-color-bg-pressed);
      scale: 96%;
      transition: all 0.2s ease-out;
    }
  }
`;
