import { createQueryKeys } from "@lukemorales/query-key-factory";

export const queryKeys: ReturnType<typeof createQueryKeys> = createQueryKeys(
  "store",
  {
    fetchCardConnect: () => ["fetchCardConnect"],
    fetchCheckedEarlypaid: (storeId: number) => [
      "fetchCheckedEarlypaid",
      storeId,
    ],
    fetchStoreInfo: (storeId: number) => ["fetchStoreInfo", storeId],
    fetchStoreStatus: (
      storeId: number,
      startDate?: string,
      endDate?: string,
    ) => ["fetchStoreStatus", storeId, startDate, endDate],
    fetchStoreDailySales: (
      storeId: number,
      startDate?: string,
      endDate?: string,
    ) => ["fetchStoreDailySales", storeId, startDate, endDate],
    fetchStoreHourlySales: (
      storeId: number,
      startDate: string,
      endDate: string,
      granularity?: string,
    ) => ["fetchStoreHourlySales", storeId, startDate, endDate, granularity],
    fetchNotification: (storeId: number) => ["fetchNotification", storeId],
    updateNotification: (storeId: number) => ["updateNotification", storeId],
  } as const,
);

type StoreQueryKeys = ReturnType<typeof createQueryKeys>;
export const storeQueryKeys: StoreQueryKeys = queryKeys;
