import {
  DailyPlatformSalesProps,
  PlatformAccountStatusCodeTypes,
  PlatformCode,
  PlatformCodeTypes,
  PlatformNames,
  PlatformSalesStatusCodeTypes,
  PlatformStatusProps,
  modalCode,
} from "@earlypay/shared";
import {
  checkSameYear,
  getDay,
  getFormattedDateKR,
  getFormattedDateWithDash,
  getMonth,
} from "@earlypay/shared/utils";
import Skeleton from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ChevronRightIcon } from "@earlybird/icons";
import {
  Box,
  BoxButton,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useConfirmModal,
} from "@earlybird/ui";

import { changeNumberToMoney } from "@/utils/types";

import {
  getPlatformImageUrl,
  getPlatformMessageColor,
  getPlatformStatus,
  getPlatformTitle,
} from "./utils";

export interface PlatformLinkListProps {
  date: Date;
  totalAmount: number;
  platforms: DailyPlatformSalesProps[];
  platformStatus: PlatformStatusProps;
  hasSales?: boolean;
  loading?: boolean;
  handleClickPlatformInfo: (
    isActive: boolean,
    isCollected: boolean,
    platform: PlatformCodeTypes,
  ) => void;
}

export const PlatformLinkList = ({
  date,
  totalAmount,
  platformStatus,
  platforms,
  loading,
  handleClickPlatformInfo,
}: PlatformLinkListProps) => {
  const { openConfirmModal } = useConfirmModal();
  const navigate = useNavigate();
  const location = useLocation();
  const newSearchParams = new URLSearchParams(location.search);

  const title = checkSameYear(date)
    ? `${getMonth(date)}월 ${getDay(date)}일`
    : `${getFormattedDateKR(date)}`;

  const EmptySection = () => (
    <VStack center spacing={3} padding={"48px 0"}>
      <Image src={"/symbol/empty.svg"} width={36} height={36} />
      <Text color={"content-quaternary"}>가져올 수 있는 내역이 없습니다.</Text>
    </VStack>
  );

  const PlatformStatusBox = ({
    platform,
    amount,
    salesStatus,
    status,
  }: DailyPlatformSalesProps & {
    status: PlatformAccountStatusCodeTypes;
    salesStatus: PlatformSalesStatusCodeTypes;
  }) => {
    const {
      isBlocked,
      isActive,
      isPending,
      isLoginFailed,
      isDisableConnectedPlatform,
      statusCode,
    } = getPlatformStatus({
      status,
      salesStatus,
      date,
    });

    const handleClickLinkButton = () => {
      if (platform === PlatformCode.CARD) {
        return openConfirmModal({
          id: modalCode.NOTICE_COLLECTED_CARD_SALES,
        });
      }

      if (isDisableConnectedPlatform) {
        return openConfirmModal({
          id: modalCode.NOTICE_DISABLED_CONNECTED_SALES,
        });
      }

      navigate(`/my/delivery-account?${newSearchParams}`, {
        state: { from: `${location.pathname}?${location.search}` },
      });
    };

    return (
      <PlatformBoxButton
        className={!isBlocked && !isLoginFailed ? `active` : `disabled`}
        onClick={() => handleClickPlatformInfo(isActive, isPending, platform)}
      >
        <HStack
          justify={"between"}
          padding={"6px 0"}
          spacing={4}
          align={"center"}
        >
          <HStack spacing={4} align={"center"}>
            <Box width={36} height={36}>
              <PlatformImage
                className={`${platform}-logo-image`}
                src={getPlatformImageUrl(platform, isBlocked)}
              />
            </Box>
            <VStack>
              <Text
                typo={"caption-1"}
                bold
                color={isBlocked ? "content-disabled" : "content-tertiary"}
              >
                {PlatformNames[platform]}
              </Text>
              {loading ? (
                <Skeleton width={120} height={24} />
              ) : (
                <Text
                  color={getPlatformMessageColor(statusCode, isActive)}
                  bold
                >
                  {isActive
                    ? changeNumberToMoney(amount)
                    : getPlatformTitle(statusCode)}
                </Text>
              )}
            </VStack>
          </HStack>

          {(isBlocked || isLoginFailed) && (
            <BoxButton
              size={"xs"}
              state={"fit"}
              onClick={handleClickLinkButton}
            >
              {isBlocked ? "연결하기" : "다시 연결하기"}
            </BoxButton>
          )}
        </HStack>
      </PlatformBoxButton>
    );
  };

  return (
    <VStack spacing={5}>
      {/** 타이틀 */}
      <HStack justify={"between"} align={"center"} padding={"0 20px"}>
        <Text typo={"body-1"} bold>
          {title}
        </Text>
        <TotalAmountLink
          href={`/calendar/detail?selectedDate=${getFormattedDateWithDash(
            date,
          )}`}
        >
          <Text>총 {changeNumberToMoney(totalAmount)}</Text>
          <Icon
            icon={ChevronRightIcon}
            size={"sm"}
            color={"content-quaternary"}
          />
        </TotalAmountLink>
      </HStack>

      {/** 플랫폼 연동 여부 리스트 */}
      <VStack spacing={3} padding={"0 16px 16px 16px"}>
        {loading ? (
          [1, 2, 3, 4].map((index: number) => (
            <Box width={"100%"} padding={"4px 0"} key={index}>
              <Skeleton
                containerClassName={"flex-1"}
                width={"100%"}
                height={42}
              />
            </Box>
          ))
        ) : !platforms?.length ? (
          <EmptySection />
        ) : (
          platforms.map(
            ({
              platform,
              amount,
              status: salesStatus, // 플랫폼 매출 수집 상태
            }: DailyPlatformSalesProps) => (
              <PlatformStatusBox
                key={platform}
                platform={platform}
                amount={amount}
                salesStatus={salesStatus}
                status={platformStatus[platform.toLowerCase()]}
              />
            ),
          )
        )}
      </VStack>
    </VStack>
  );
};

export default PlatformLinkList;

const PlatformImage = styled(Image).attrs(() => ({
  width: 36,
  height: 36,
}))`
  border-radius: 12px;
  object-fit: cover;

  &:where(.COUPANG-logo-image) {
    border: 1px solid var(--earlybird-color-border-tertiary);
  }
`;

const PlatformBoxButton = styled.div`
  width: 100%;
  background-color: transparent;
  border-radius: var(--earlybird-radius-lg);

  &:where(.active) {
    cursor: pointer;

    &:hover {
      background-color: var(--earlybird-color-bg-hovered);
      transition: all 0.2s ease-out;
    }

    &:active {
      background-color: var(--earlybird-color-bg-pressed);
      scale: 96%;
      transition: all 0.2s ease-out;
    }
  }
`;

const TotalAmountLink = styled.a`
  display: flex;
  width: fit-content;
  align-items: center;
  text-decoration: none;
`;
