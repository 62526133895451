import { useState } from "react";

import { trackEventMixpanel } from "@earlypay/shared";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Box, Text } from "@earlybird/ui";

import { useFetchStoreHourlySales } from "@/apis/hooks";

interface SalesStats {
  amountSales: number;
  hour: string;
  numSales: number;
}

const TIME_DATA = [
  "0시",
  "1시",
  "2시",
  "3시",
  "4시",
  "5시",
  "6시",
  "7시",
  "8시",
  "9시",
  "10시",
  "11시",
  "12시",
  "13시",
  "14시",
  "15시",
  "16시",
  "17시",
  "18시",
  "19시",
  "20시",
  "21시",
  "22시",
  "23시",
];

const HourlySales = ({ storeId }: { storeId: number }) => {
  const formatDate = (date: Date) => date.toISOString().split("T")[0];

  function getDateOneWeekAgo(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format. Please use YYYY-MM-DD.");
    }

    date.setDate(date.getDate() - 7);

    return date.toISOString().split("T")[0];
  }

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayString = formatDate(yesterday);

  const [selectedDate, setSelectedDate] = useState<string>(yesterdayString);

  function getLast7Days(): string[] {
    const today = new Date();

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const dates = Array(7)
      .fill(0)
      .map((_, i) => {
        const date = new Date(yesterday);
        date.setDate(yesterday.getDate() - i);
        return date.toISOString().split("T")[0];
      });

    return dates.slice().reverse();
  }

  const last7Days = getLast7Days();
  const lastWeek = getDateOneWeekAgo(selectedDate);

  function getDayOfWeek(dateString: string, isShortType?: boolean): string {
    const daysOfWeek = [
      "일요일",
      "월요일",
      "화요일",
      "수요일",
      "목요일",
      "금요일",
      "토요일",
    ];

    const daysOfWeekShort = ["일", "월", "화", "수", "목", "금", "토"];

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format. Please use YYYY-MM-DD.");
    }

    if (isShortType) {
      return daysOfWeekShort[date.getDay()];
    }

    return daysOfWeek[date.getDay()];
  }

  function formatDateLabel(dateString: string): string {
    const inputDate = new Date(dateString);
    const today = new Date();

    if (isNaN(inputDate.getTime())) {
      throw new Error("Invalid date format. Please use YYYY-MM-DD.");
    }

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const dayBeforeYesterday = new Date(today);
    dayBeforeYesterday.setDate(today.getDate() - 2);

    if (
      inputDate.getFullYear() === yesterday.getFullYear() &&
      inputDate.getMonth() === yesterday.getMonth() &&
      inputDate.getDate() === yesterday.getDate()
    ) {
      return "어제";
    }

    if (
      inputDate.getFullYear() === dayBeforeYesterday.getFullYear() &&
      inputDate.getMonth() === dayBeforeYesterday.getMonth() &&
      inputDate.getDate() === dayBeforeYesterday.getDate()
    ) {
      return "그저께";
    }

    // 어제/그저께가 아닌 경우 MM.DD 형식 반환
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    return `${month}.${day}`;
  }

  const { data: lastWeekHourly, isLoading: lastWeekLoading } =
    useFetchStoreHourlySales(storeId, lastWeek, lastWeek, "1");
  const { data: thisWeekHourly, isLoading: thisWeekLoading } =
    useFetchStoreHourlySales(storeId, selectedDate, selectedDate, "1");

  const modifiedLastWeekSales = lastWeekHourly?.salesStats;
  const modifiedThisWeekSales = thisWeekHourly?.salesStats;

  // const maxAmountSales = Math.max(
  //   ...(modifiedThisWeekSales || []).map((stat: SalesStats) =>
  //     stat.numSales > 0
  //       ? Number((stat.amountSales / stat.numSales).toFixed())
  //       : 0,
  //   ),
  // );
  // const maxRevenueIndex = modifiedThisWeekSales?.findIndex(
  //   (item: SalesStats) =>
  //     Number((item.amountSales / item.numSales).toFixed()) === maxAmountSales,
  // );

  let currentLegends = "지난주/이번주";

  const handleDateChange = (date: string) => {
    setSelectedDate(date);
    trackEventMixpanel("click_chart", {
      item: "날짜 필터",
      "selected item label":
        date === yesterdayString
          ? "어제"
          : `D${
              (-1 *
                (new Date(yesterdayString).getTime() -
                  new Date(date).getTime())) /
                (1000 * 60 * 60 * 24) -
              1
            }`,
      "activated options": currentLegends,
    });
  };

  const series = [
    {
      name: `지난주 ${getDayOfWeek(lastWeek)}`,
      data: modifiedLastWeekSales?.map((item: SalesStats) =>
        item.numSales > 0 ? Number(item.amountSales.toFixed()) : 0,
      ),
    },
    {
      name: `이번주 ${getDayOfWeek(selectedDate)}`,
      data: modifiedThisWeekSales?.map((item: SalesStats) =>
        item.numSales > 0 ? Number(item.amountSales.toFixed()) : 0,
      ),
    },
  ];

  const options: ApexOptions = {
    theme: {
      mode: "light",
    },
    chart: {
      type: "line",
      height: 254,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        click: (event, chartContext, config) => {
          const text = event.target.innerText;

          if (currentLegends === "지난주/이번주") {
            currentLegends = text?.includes("지난주")
              ? "이번주"
              : text?.includes("이번주")
              ? "지난주"
              : currentLegends;
          } else if (currentLegends === "지난주") {
            currentLegends = text?.includes("지난주")
              ? "없음"
              : text?.includes("이번주")
              ? "지난주/이번주"
              : currentLegends;
          } else if (currentLegends === "이번주") {
            currentLegends = text?.includes("이번주")
              ? "없음"
              : text?.includes("지난주")
              ? "지난주/이번주"
              : currentLegends;
          } else if (currentLegends === "없음") {
            currentLegends = text?.includes("지난주")
              ? "지난주"
              : text?.includes("이번주")
              ? "이번주"
              : currentLegends;
          }

          trackEventMixpanel("click_chart", {
            item:
              event.target.className === "apexcharts-legend-text"
                ? "필터"
                : "차트",
            "selected item label":
              config?.seriesIndex === -1
                ? "차트 아님"
                : `${config?.seriesIndex === 1 ? "이번주" : "지난주"} ${
                    config?.dataPointIndex
                  }시`,
            "activated options": currentLegends,
          });
        },
      },
    },
    stroke: {
      curve: "straight",
    },
    colors: ["#D3D3D3", "#4A85FF"],
    xaxis: {
      categories: TIME_DATA,
      labels: {
        rotate: 0,
        formatter: value => {
          const replaceValue = value?.replace("시", "");
          return Number(replaceValue) % 4 === 0 || replaceValue === "23"
            ? `${replaceValue}시`
            : "";
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      itemMargin: {
        horizontal: 12,
        vertical: 4,
      },
    },
    tooltip: {
      x: {
        formatter: (value, { dataPointIndex }) => TIME_DATA[dataPointIndex],
      },
      y: {
        formatter: value => `${Number(value).toLocaleString("ko-KR")}원`,
      },
    },
    // annotations:
    //   maxAmountSales > 0
    //     ? {
    //         points: [
    //           {
    //             x: TIME_DATA[maxRevenueIndex],
    //             y: maxAmountSales,
    //             yAxisIndex: 0,
    //             marker: {
    //               size: 6,
    //               fillColor: "#4A85FF",
    //               strokeColor: "#FFFFFF",
    //               shape: "circle",
    //             },
    //             label: {
    //               text: `최고 ${(maxAmountSales / 10000).toFixed(1)}만원`,
    //               borderColor: "transparent",
    //               style: {
    //                 color: "#0E5CFF",
    //                 fontSize: "12px",
    //                 fontWeight: 600,
    //                 background: "transparent",
    //               },
    //             },
    //           },
    //         ],
    //       }
    //     : {},
  };

  if (lastWeekLoading || thisWeekLoading || !thisWeekHourly || !lastWeekHourly)
    return <LoadingSkeleton />;

  return (
    <Box
      radius={"xl"}
      width={"100%"}
      bg="bg-primary"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <Box padding="20px 16px 8px 16px" width="100%">
        <Text color="content-primary" weight="semi-bold" typo="body-2">
          시간대별 매출
        </Text>
        <DateWrapper>
          {last7Days.map((date, index) => (
            <Box
              key={`${date}-${index}`}
              bg={selectedDate === date ? "bg-primary" : ""}
              padding="4px 0"
              width="100%"
              radius="md"
              display="flex"
              style={{
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleDateChange(date)}
            >
              <Text
                color={
                  selectedDate === date ? "content-primary" : "content-disabled"
                }
                weight="semi-bold"
              >
                {getDayOfWeek(date, true)}
              </Text>
              <Text color="content-quaternary" typo="caption-2">
                {formatDateLabel(date)}
              </Text>
            </Box>
          ))}
        </DateWrapper>
        <ReactApexChart
          type="line"
          options={options}
          series={series}
          height={254}
        />
      </Box>
    </Box>
  );
};

export default HourlySales;

const DateWrapper = styled.div`
  background-color: #eaeaea;
  border-radius: 8px;
  padding: 3px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const LoadingSkeleton = () => {
  return (
    <Box
      radius={"xl"}
      width={"100%"}
      bg="bg-primary"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <Box padding="20px 16px 20px 16px" width="100%">
        <Text color="content-primary" weight="semi-bold" typo="body-2">
          시간대별 매출
        </Text>
        <Skeleton
          width="100%"
          height={52}
          style={{ borderRadius: "8px", marginTop: "16px" }}
        />
        <Skeleton width="100%" height={226} style={{ marginTop: "36px" }} />
      </Box>
    </Box>
  );
};
