import React from "react";

import { EtcAmountListTypes, EtcAmountNames } from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

import { changeNumberToMoney } from "@utils/types";

export interface EtcAmountListProps {
  list: EtcAmountListTypes[];
  isLoading: boolean;
}

export const EtcAmountList: React.FC<EtcAmountListProps> = ({
  list,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <SalesWrapper>
        <Skeleton width={120} height={18} />
        <Skeleton width={100} height={18} />
      </SalesWrapper>
    );
  }

  return (
    <Wrapper>
      {list.map((item, index) => (
        <InnerWrapper key={index}>
          <SalesWrapper>
            <Text bold color={"content-tertiary"}>
              {EtcAmountNames[item.type]}
            </Text>
            <Text bold color={"content-secondary"}>
              {changeNumberToMoney(item.amount)}
            </Text>
          </SalesWrapper>
        </InnerWrapper>
      ))}
    </Wrapper>
  );
};

export default EtcAmountList;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: default;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const SalesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
