import React, { lazy } from "react";

import {
  BillingNames,
  DepositorCode,
  DepositorNames,
} from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

import { BillingAmountList } from "@components/features/settlement/SettlementAmountList";
import { Header, Meta } from "@components/layouts";

import { useSettlement } from "@hooks/useSettlement";

import { useFetchEarlypays, useFetchEarlypaysEtc } from "@apis/hooks";

import { changeNumberToMoney } from "@utils/types";

import { changeDepositorToCode, changeDepositorToTitle } from "../utils";

const LazyEtcBottomSheets = lazy(() => import("./EtcBottomSheets"));
const LazyDepositorsBottomSheets = lazy(
  () => import("./DepositorsBottomSheets"),
);

export const Billing: React.FC = () => {
  const {
    getFormattedDate,
    depositorParams,
    startDateParams,
    endDateParams,
    filterParams,
    prevPath,
  } = useSettlement();
  const navigate = useNavigate();
  const title = changeDepositorToTitle(depositorParams);
  const depositor = changeDepositorToCode(depositorParams);

  /** 각 입금사 별 선정산 내역 API 를 호출합니다. */
  const { data: earlypaysData, isLoading: isEarlypaysLoading } =
    useFetchEarlypays({
      startDate: startDateParams,
      endDate: endDateParams,
      depositor: depositor,
    });
  /** 그 외 선정산 정산 내역 API 를 호출합니다. */
  const { data: earlypaysEtcData, isLoading: isEtcLoading } =
    useFetchEarlypaysEtc({
      startDate: startDateParams,
      endDate: endDateParams,
    });
  const isLoading = isEarlypaysLoading || isEtcLoading;
  /** 각 입금사 별 선정산 총 합계 */
  const totalAmount = !isLoading
    ? depositor === "ETC"
      ? earlypaysEtcData.totalAmount
      : earlypaysData.totalAmount
    : 0;

  const handleGoToBack = () => {
    if (!prevPath) {
      return navigate(
        `/settlement?startDate=${startDateParams}&endDate=${endDateParams}`,
      );
    }

    return navigate(prevPath);
  };

  return (
    <Container>
      <Meta
        title={"얼리페이 | 정산내역"}
        name={filterParams ? `입금사 상세 내역` : `입금사 내역`}
        options={
          filterParams
            ? {
                "Start Date": startDateParams,
                "End Date": endDateParams,
                depositor: DepositorNames[depositor],
                filter: filterParams,
                title: `${BillingNames[filterParams]}`,
              }
            : {
                "Start Date": startDateParams,
                "End Date": endDateParams,
                depositor: DepositorNames[depositor],
                title: `${title} 선정산`,
              }
        }
      />
      <Header
        handleClickLeftButton={handleGoToBack}
      >{`${title} 선정산`}</Header>
      <Wrapper>
        {/** 날짜 및 선정산 총 합계를 표시합니다. */}
        <TitleWrapper>
          <Text color={"content-tertiary"} bold>
            {getFormattedDate()}
          </Text>
          {isLoading ? (
            <Skeleton width={120} height={28} />
          ) : (
            <Text typo={"title-3"} bold>
              {changeNumberToMoney(totalAmount)}
            </Text>
          )}
        </TitleWrapper>

        {/** 각 입금사 별 선정산 내역을 보여줍니다. */}
        <InnerWrapper>
          <BillingAmountList
            list={
              depositor === DepositorCode.ETC
                ? earlypaysEtcData?.items
                : earlypaysData?.items
            }
            isLoading={isLoading}
          />
        </InnerWrapper>

        {/** 각 입금사 별 선정산 세부 항목이 담긴 바텀 시트를 보여줍니다. */}
        {filterParams && depositor !== DepositorCode.ETC ? (
          <LazyDepositorsBottomSheets />
        ) : (
          <LazyEtcBottomSheets />
        )}
      </Wrapper>
    </Container>
  );
};

export default Billing;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow: scroll;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;
