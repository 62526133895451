export interface messageDummyDataProps {
  [key: number | string]: {
    content: string;
    name: string;
    region: string;
  };
}

export const messageDummyData: messageDummyDataProps = {
  0: {
    content:
      "타사 서비스는 정산 시간이 불규칙해서 얼리페이로 바꿨습니다. 번거롭게 배달앱마다 매출 확인할 필요가 없고 현금흐름이 좋아졌습니다.",
    name: "최OO",
    region: "서울",
  },
  1: {
    content:
      "배달앱, 카드사별로 흩어진 매출을 한 눈에 볼 수 있어 좋아요. 매출 누락 건도 알아서 방지해 주니 정말 정산이 편해졌어요!",
    name: "장OO",
    region: "통영",
  },
  2: {
    content:
      "정산 주기가 너무 길었던 요기요 매출을 매일 정산 받아 좋아요. 덕분에 정산도 쉬워지고 자금 흐름도 빨라졌어요 감사합니다!",
    name: "이OO",
    region: "장성",
  },
  3: {
    content:
      "연휴, 주말에도 매일 정산은 물론 알림톡으로 정산 내역을 간편하게 확인할 수 있어 좋습니다. 덕분에 자금 회전도 빨라져 가게 운영이 한결 나아졌습니다.",
    name: "김OO",
    region: "서울",
  },
  4: {
    content:
      "저도 몰랐던 누락 매출을 알아서 찾아 정산 입금해 주다니, 역시 믿고 쓰는 얼리페이! 매출 데이터 덕분에 월말에 매출, 재고, 손익 등 계산이 쉬워졌습니다.",
    name: "신OO",
    region: "광주",
  },
  5: {
    content:
      "주말에도 선정산을 받으니, 식자재 발주비 걱정이 사라졌습니다. 정산도 늘 정확해서 믿음이 갑니다. 감사합니다.",
    name: "김OO",
    region: "서울",
  },
  6: {
    content:
      "처음엔 정산이 제대로 될 지 걱정했지만, 투명하고 정확한 정산을 몇 번 경험하니 이제 100% 신뢰해요. 덕분에 자금 수급이 잘 되고 가게 운영 수고를 덜어주셔서 고맙습니다~",
    name: "최OO",
    region: "목포",
  },
  7: {
    content:
      "매일 영업 마감 후 포스기랑 배달앱, 카드사 매출 내역을 맞춰보느라 머리가 아팠는데, 빠르고 정확한 정산 덕분에 퇴근이 빨라졌어요!",
    name: "남OO",
    region: "여주",
  },
  8: {
    content:
      "자금 순환이 잘 되어 이제 주말에도 식자재 발주가 가능해요. 매출 정산도 쉬워져, 가게 운영에만 집중할 수 있어 좋아요.",
    name: "이OO",
    region: "인천",
  },
  9: {
    content:
      "악성 민원에 힘들었는데, 얼리페이가 무료 제공해 준 손해배상보험 덕분에 어려움 없이 해결했어요. 따로 보험 가입할 필요 없어 너무 좋네요~",
    name: "허OO",
    region: "서울",
  },
};
