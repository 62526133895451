import React, { useState } from "react";

import { CircleQuestionMarkSolidIcon } from "@earlybird/icons";
import { IconButton } from "@earlybird/ui";

import { InfoTypes } from "@components/organisms/InfoBottomSheet/types";
import {
  getInfoContent,
  getInfoTitle,
} from "@components/organisms/InfoBottomSheet/utils";

import BottomSheet from "../../molecules/BottomSheet";

interface InfoBottomSheetProps {
  type: InfoTypes;
}

export const InfoBottomSheet: React.FC<InfoBottomSheetProps> = ({ type }) => {
  const [isVisibleInfo, setIsVisibleInfo] = useState(false);
  const title = getInfoTitle(type);
  const children = getInfoContent(type);

  /** 안내 바텀 시트를 닫습니다. */
  const handleCloseBottomSheet = () => {
    setIsVisibleInfo(false);
  };

  /** 안내 바텀 시트를 엽니다. */
  const handleOpenBottomSheet = () => {
    setIsVisibleInfo(true);
  };

  return (
    <>
      {/** 안내 아이콘 */}
      <IconButton
        icon={CircleQuestionMarkSolidIcon}
        onClick={handleOpenBottomSheet}
        color={"content-tertiary"}
        size={"sm"}
      ></IconButton>

      {/** 안내 아이콘을 누를 시, 안내 내용이 답긴 바텀시트를 보여줍니다. */}
      {isVisibleInfo && (
        <BottomSheet
          onClose={handleCloseBottomSheet}
          headerText={title}
          primaryButtonText={"확인"}
        >
          {children}
        </BottomSheet>
      )}
    </>
  );
};

export default InfoBottomSheet;
