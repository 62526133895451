import { Text } from "@earlybird/ui";

import { PriceTitleStack, PriceWrapper } from "./index.styled";

const PriceSection = () => {
  return (
    <PriceWrapper>
      <PriceTitleStack>
        <Text className="earlypay-landing-title" as="h2" typo={"title-1"} bold>
          이 모든 서비스가
        </Text>
        <Text
          className="earlypay-landing-title"
          as="h3"
          typo={"title-3"}
          bold
          color={"content-highlight"}
        >
          하루 2,000원*
        </Text>
      </PriceTitleStack>
      <Text
        className="earlypay-landing-content-text"
        center
        color={"content-secondary"}
        as="h5"
      >
        선정산 및 매출리포트, 책임보험까지
        <br />이 모든 것을 하루 이천 원에 누려보세요
      </Text>
      <Text typo={"caption-1"} light color={"content-tertiary"}>
        *부가세 별도
      </Text>
    </PriceWrapper>
  );
};

export default PriceSection;
