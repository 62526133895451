import { Suspense } from "react";

import { pdfWorkerPolyfill } from "@earlypay/shared";
import { routers } from "@routes/routers";
import "@styles/global.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";

import {
  Box,
  ConfirmModalProvider,
  Spinner,
  ToastProvider,
} from "@earlybird/ui";

import { queryClient } from "@apis/hooks/queryClient";

function App() {
  pdfWorkerPolyfill();

  return (
    <Suspense
      fallback={
        <Box width={"100%"} height={"100vh"} center>
          <Spinner />
        </Box>
      }
    >
      <ToastProvider>
        <ConfirmModalProvider>
          <QueryClientProvider client={queryClient}>
            <RecoilRoot>
              <SkeletonTheme baseColor="#F4F4F4" highlightColor="#fafafa">
                <RouterProvider router={routers} />
              </SkeletonTheme>
              <ReactQueryDevtools initialIsOpen={false} />
            </RecoilRoot>
          </QueryClientProvider>
        </ConfirmModalProvider>
      </ToastProvider>
    </Suspense>
  );
}

export default App;
