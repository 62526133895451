import { lazy, useEffect } from "react";

import { applicationState } from "@recoil/application/atom";
import { useRecoilValue } from "recoil";

import { PageContainer, VStack } from "@earlybird/ui";

import { Meta } from "@components/layouts";
import { TodayDepositsCard } from "@components/modules/home/index";

import { useFetchStoreStatus } from "@/apis/hooks";
import { VocEarlypayBoard } from "@/components/features/feedback";
import MainHeader from "@/components/layouts/MainHeader";
import DailySales from "@/components/modules/home/DailySales";
import HourlySales from "@/components/modules/home/HourlySales";
import PreviousDateSales from "@/components/modules/home/PreviousDateSales";
import { getCookie } from "@/utils/cookie";

export const LazyEventBanner = lazy(
  () => import("@components/modules/home/EventBanner"),
);

const Home = () => {
  const application = useRecoilValue(applicationState);
  const storeId = application.store.id;

  const findDate = ({ dayOffset }: { dayOffset: number }) => {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const paramDate = `${year}-${month}-${day}`;

    return paramDate;
  };

  const { data: storeStatus, isLoading: storeStatusLoading } =
    useFetchStoreStatus(
      storeId,
      findDate({ dayOffset: -2 }),
      findDate({ dayOffset: 0 }),
    );

  useEffect(() => {
    const inviteEventCookie = getCookie("thanksgiving");
    if (inviteEventCookie === "true") return;
  }, []);

  return (
    <>
      <Meta title="얼리페이 | 홈" name={"홈"} />
      <MainHeader />
      <PageContainer
        padding={"6px 20px 100px 20px"}
        style={{ backgroundColor: "#F4F4F4" }}
      >
        <VStack spacing={6}>
          <VStack spacing={5}>
            {/* <Suspense
          fallback={
            <Box width={"100%"} height={110} center>
              <Spinner />
            </Box>
          }
        >
          <LazyEventBanner />
        </Suspense> */}
            <TodayDepositsCard
              storeId={storeId}
              storeStatus={storeStatus}
              storeStatusLoading={storeStatusLoading}
            />
            <PreviousDateSales
              storeId={storeId}
              storeStatus={storeStatus}
              storeStatusLoading={storeStatusLoading}
            />
            <DailySales storeId={storeId} />
            <HourlySales storeId={storeId} />
            <VocEarlypayBoard category={"home"} />
          </VStack>
        </VStack>
      </PageContainer>
    </>
  );
};

export default Home;
