import React from "react";

import {
  UnderDepositDetailItemsTypes,
  UnderDepositDetailTypes,
} from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

import EmptyAmountBox from "@components/organisms/EmptyAmountBox";

import { getFormattedDateWithDots } from "@/utils/date";

import UnderDepositAmountItem from "./item.card";

export interface UnderDepositAmountListProps {
  list: UnderDepositDetailTypes[];
  isLoading: boolean;
}

export const UnderDepositAmountList: React.FC<UnderDepositAmountListProps> = ({
  list,
  isLoading,
}) => {
  if (!list?.length) {
    return <EmptyAmountBox label={"차감금 상세내역이 없습니다."} />;
  }

  return (
    <Wrapper>
      {list.map((data, index) => {
        const { repaymentDate, items } = data;
        const date = new Date(repaymentDate);

        return (
          <InnerWrapper key={index}>
            <Text bold>
              {isLoading ? (
                <Skeleton height={22} width={80} />
              ) : (
                getFormattedDateWithDots(date)
              )}
            </Text>

            <ItemsWrapper>
              {items?.map(
                (item: UnderDepositDetailItemsTypes, index: number) => (
                  <UnderDepositAmountItem
                    key={index}
                    item={item}
                    isLoading={isLoading}
                  />
                ),
              )}
            </ItemsWrapper>
          </InnerWrapper>
        );
      })}
    </Wrapper>
  );
};

export default UnderDepositAmountList;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;
