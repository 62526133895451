import React from "react";

import styled from "styled-components";

import { SquareCheckSolidIcon, SquareIcon } from "@earlybird/icons";
import { Icon } from "@earlybird/ui";

interface CheckBoxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange }) => {
  const handleClickCheckBox = (event: React.MouseEvent) => {
    event.stopPropagation();
    onChange(!checked);
  };

  return (
    <Wrapper onClick={handleClickCheckBox}>
      <ActiveCheckBox
        icon={SquareCheckSolidIcon}
        size={"md"}
        color={"content-highlight"}
        checked={checked}
      />
      <InActiveCheckBox checked={checked} icon={SquareIcon} size={"md"} />
    </Wrapper>
  );
};

export default CheckBox;

const Wrapper = styled.button`
  position: relative;
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

const ActiveCheckBox = styled(Icon)`
  opacity: ${({ checked }: { checked: boolean }) => (checked ? 1 : 0)};
  transition: opacity 0.3s ease;
  position: absolute;
`;

const InActiveCheckBox = styled(Icon)`
  opacity: ${({ checked }: { checked: boolean }) => (checked ? 0 : 1)};
  transition: opacity 0.3s ease;
  position: absolute;
`;
