import { ArrowRightIcon } from "@earlybird/icons";
import { Icon, Text } from "@earlybird/ui";

import { LandingBannerStack, LandingBannerWrapper } from "./index.styled";

export interface TopBannerProps {
  handleClickTrialButton: (title?: string, position?: string) => void;
}

const TopBanner = (props: TopBannerProps) => {
  return (
    <LandingBannerWrapper
      onClick={() =>
        props.handleClickTrialButton(
          "지금 이용 신청 시, 한 달 간 무료 혜택",
          "배너/하단 Floating 버튼",
        )
      }
    >
      <LandingBannerStack>
        <Text typo="subtitle-2" bold color="content-on-color">
          지금 이용 신청 시, 한 달 간 무료 혜택
        </Text>
        <Text color="content-on-color">한 달 무료 이용하기</Text>
      </LandingBannerStack>
      <Icon
        icon={ArrowRightIcon}
        color={"content-on-color"}
        size={"sm"}
        style={{ marginLeft: 2 }}
      />
    </LandingBannerWrapper>
  );
};

export default TopBanner;
