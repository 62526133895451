import ReactPlayer from "react-player";

import { Text } from "@earlybird/ui";

import Stack from "@components/atoms/Stack";
import { StackDirectionsTypes } from "@components/atoms/Stack/type";

import { EARLYPAY_REVIEW_YOUTUBE } from "@/commons/constants";

import { ReviewCardCarousel } from "../ReviewCardCarousel";
import { ContentStack, ReviewWrapper } from "./index.styled";

const ReviewSection = () => {
  return (
    <ReviewWrapper>
      <Stack center>
        <Text
          className="earlypay-landing-title"
          as="h2"
          center
          typo={"title-1"}
          bold
        >
          이미 많은 사장님들께서
        </Text>
        <Stack direction={StackDirectionsTypes.ROW}>
          <Text
            className="earlypay-landing-title"
            typo={"title-1"}
            bold
            color={"content-highlight"}
            as="h2"
          >
            얼리페이
          </Text>
          <Text
            className="earlypay-landing-title"
            as="h2"
            center
            typo={"title-1"}
            bold
          >
            와 함께하고 있어요
          </Text>
        </Stack>
      </Stack>
      <Text
        className="earlypay-landing-subtitle"
        center
        color={"content-secondary"}
        as="h4"
      >
        2022 서비스 만족도 조사 결과 평점 4.5점
        <br />
        얼리페이와 함께하는 사장님의 생생한 후기
      </Text>
      <ContentStack>
        <ReviewCardCarousel />
        <ReactPlayer
          className="earlypay-landing-youtube"
          width={560}
          height={315}
          url={EARLYPAY_REVIEW_YOUTUBE}
        />
      </ContentStack>
    </ReviewWrapper>
  );
};

export default ReviewSection;
