import React, { useState } from "react";

import styled from "styled-components";

import { ChevronDownSolidIcon } from "@earlybird/icons";
import { Icon, Text } from "@earlybird/ui";

import Interaction from "@components/atoms/Interaction";
import DatePickerModal from "@components/organisms/DatePickerModal";

import useSettlement from "@hooks/useSettlement";

import { useFetchApplication } from "@apis/hooks";

import { getMonthDifference, getSelectedDate } from "./utils";

export const SettlementDatePicker: React.FC = () => {
  const { startDateParams, endDateParams } = useSettlement();
  // TODO: startDate, endDate 를 하나의 배열 안에 넣어서 관리 필요
  const [startDate, setStartDate] = useState(startDateParams);
  const [endDate, setEndDate] = useState(endDateParams);
  const [isVisibleDateModal, setIsVisibleDateModal] = useState<boolean>(false);

  const { data: application } = useFetchApplication();

  const minShowMonths = application.approvedAt
    ? getMonthDifference(application.approvedAt)
    : 3;
  const activeStartDate = new Date(application.approvedAt);
  activeStartDate.setDate(activeStartDate.getDate() - 1);

  const handleClickDate = () => {
    setIsVisibleDateModal(true);
  };

  const handleCloseModal = () => {
    setIsVisibleDateModal(false);
  };

  return (
    <>
      <Interaction type={"DARK"} rounded={"SM"}>
        <SelectedDateButton onClick={handleClickDate}>
          <Text bold>{getSelectedDate(startDateParams, endDateParams)}</Text>
          <Icon icon={ChevronDownSolidIcon} />
        </SelectedDateButton>
      </Interaction>
      {isVisibleDateModal && (
        <DatePickerModal
          onClose={handleCloseModal}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          minShowMonths={minShowMonths}
          activeStartDate={activeStartDate}
        />
      )}
    </>
  );
};

export default SettlementDatePicker;

const SelectedDateButton = styled.button`
  width: fit-content;
  display: flex;
  background: transparent;
`;
