import { EarlypayLogoIcon } from "@earlybird/icons";
import { BoxButton, Icon } from "@earlybird/ui";

import {
  CustomButtonWrapper,
  HeaderWrapper,
  LandingHeader,
  LogoStack,
} from "./index.styled";

export interface LandingHeaderProps {
  handleClickTrialButton: (title?: string, position?: string) => void;
}

const LadingHeader = (props: LandingHeaderProps) => {
  return (
    <LandingHeader>
      <HeaderWrapper>
        <LogoStack>
          <Icon
            className="earlypay-landing-header-logo"
            icon={EarlypayLogoIcon}
            color={"content-highlight"}
            style={{ width: 143, height: 32 }}
          />
        </LogoStack>
        <CustomButtonWrapper>
          <BoxButton
            className="header-login-button"
            onClick={() => props.handleClickTrialButton("로그인", "헤더")}
            size={"sm"}
            hierarchy={"tertiary"}
            state={"fit"}
          >
            로그인
          </BoxButton>
          <BoxButton
            className="header-trial-button"
            onClick={() =>
              props.handleClickTrialButton("한 달 무료 이용하기", "헤더")
            }
            size={"sm"}
            state={"fit"}
          >
            한 달 무료 이용하기
          </BoxButton>
        </CustomButtonWrapper>
      </HeaderWrapper>
    </LandingHeader>
  );
};

export default LadingHeader;
