import React from "react";

import styled from "styled-components";

import { Text } from "@earlybird/ui";

/** 제외 매출 안내 */
export const ExcludeSalesInfo: React.FC = () => {
  return (
    <ExcludeWrapper>
      <Message style={{ paddingBottom: 8 }}>
        미연동 매출과 제외 매출을 말해요.
      </Message>
      <ListWrapper>
        <List>
          미연동 매출은 아직 카드사 혹은 배달사 연동이 되지 않아서 선정산 대상에
          포함되지 않는 매출을 말해요.
        </List>
        <List>
          제외 매출은 고액건 혹은 반복결제, 특정 카드사/배달사 비활성화로 인해
          선정산에서 제외되는 매출을 말해요.
        </List>
      </ListWrapper>
    </ExcludeWrapper>
  );
};

/** 기타 미상환금 안내 */
export const EtcUnderDepositInfo: React.FC = () => {
  return (
    <ExcludeWrapper>
      <Message>
        배달 플랫폼 내에서 파악 불가능한 사유로 인해 지급 받은 선정산 금액보다
        적게 회수되어 발생한 미상환금입니다. 정액요금제 결제, 주문 취소/환불
        등으로 인해 발생할 수 있습니다.
      </Message>
    </ExcludeWrapper>
  );
};

/** 추가 선정산 안내 */
export const AdditionalDepositInfo: React.FC = () => {
  return (
    <ExcludeWrapper>
      <Message>
        일부 매출에 대해 누락된 선정산금을 추가록 입금해 드리는 금액입니다.
      </Message>
    </ExcludeWrapper>
  );
};

/** 기타 추가입금 안내 */
export const EtcAdditionalDepositInfo: React.FC = () => {
  return (
    <ExcludeWrapper>
      <Message>
        선정산 매출에 해당되지 않는 금액이 경남은행 계좌에 추가로 입금된 경우
        돌려드리는 금액입니다.
      </Message>
    </ExcludeWrapper>
  );
};

/** 기타 안내 */
export const EtcInfo: React.FC = () => {
  return (
    <ExcludeWrapper>
      <Message>
        지급된 선정산금보다 회수된 금액이 많아 추가로 입금해 드리는 금액으로,
        당일 18시 전후로 입금됩니다.
      </Message>
    </ExcludeWrapper>
  );
};

export const ExcludeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Message = styled(Text).attrs({
  color: "content-secondary",
})`
  font-size: 16px;
  color: #525252;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: Pretendard;
`;

export const ListWrapper = styled.ul`
  padding-left: 23px;
`;

export const List = styled.li`
  list-style: outside;
  color: #525252;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: Pretendard;
`;
