import React, { useState } from "react";

import {
  DepositorCode,
  DepositorNames,
  SalesCode,
  SalesNames,
  TotalSalesTypes,
} from "@earlypay/shared/typings";
import { changeDepositorToCode } from "@pages/settlement/utils";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import { CircleQuestionMarkSolidIcon } from "@earlybird/icons";
import { IconButton, Text, Tooltip } from "@earlybird/ui";

import Stack from "@components/atoms/Stack";

import useSettlement from "@hooks/useSettlement";

import { changeNumberToMoney } from "@utils/types";

import { ColorsTypes, FontTypes } from "@/@types/ThemesType";

export interface SalesAmountListProps {
  list?: TotalSalesTypes;
  isLoading: boolean;
  type?: DepositorCode;
}

export const SalesAmountList: React.FC<SalesAmountListProps> = ({
  list,
  isLoading,
  type,
}) => {
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);
  const { depositorParams } = useSettlement();
  const depositor = changeDepositorToCode(depositorParams);

  const handleClickTooltip = () => {
    setIsActiveTooltip(!isActiveTooltip);
  };

  if (isLoading || !list) {
    return (
      <Wrapper>
        {[1, 2, 3, 4].map(index => (
          <SalesBox key={index}>
            <Skeleton height={18} width={140} />
            <Skeleton height={18} width={100} />
          </SalesBox>
        ))}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {list.items.map((item, index) => (
        <SalesBox key={index}>
          <TextWrapper>
            <Text bold color={"content-tertiary"}>
              {type
                ? `${SalesNames[type][item.type]}`
                : `${SalesNames[depositor][item.type]}`}
            </Text>

            {/** 배달 수수료 안내 툴팁 */}
            {(depositor === DepositorCode.BAEMIN ||
              depositor === DepositorCode.YOGIYO) &&
              item.type === SalesCode.FEE &&
              type !== DepositorCode.CARD && (
                <Tooltip
                  onClose={() => setIsActiveTooltip(false)}
                  defaultVisible={false}
                  fixVisible={false}
                  isVisible={isActiveTooltip}
                  width={202}
                  pointerOffset={46}
                  hasCloseIcon
                  message={
                    "배달서비스에서 부과하는 비용으로\n주문 중개 수수료, 결제 수수료,\n부가세 등이 포함돼요."
                  }
                >
                  <IconButton
                    icon={CircleQuestionMarkSolidIcon}
                    color={"content-tertiary"}
                    size={"sm"}
                    onClick={handleClickTooltip}
                  />
                </Tooltip>
              )}
          </TextWrapper>

          <Text bold color={"content-secondary"}>
            {changeNumberToMoney(item.amount)}
          </Text>
        </SalesBox>
      ))}
      <Divider />

      {/** 합계 */}
      <SalesBox>
        <Text bold>
          {type ? DepositorNames[type] : DepositorNames[depositor]} 매출
        </Text>
        <Text bold>
          {isLoading ? (
            <Skeleton height={18} width={100} />
          ) : (
            changeNumberToMoney(list.totalAmount)
          )}
        </Text>
      </SalesBox>
    </Wrapper>
  );
};

export default SalesAmountList;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: default;
`;

const SalesBox = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eaeaea;
`;

const TextWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TooltipText = styled(Text).attrs({
  tag: FontTypes.BODY_3,
  color: ColorsTypes.CONTENT_ON_COLOR,
})`
  word-break: break-all;
  display: inline;
`;

export const TooltipContent = styled(Stack)`
  display: inline;
`;
