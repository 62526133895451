import { createQueryKeys } from "@lukemorales/query-key-factory";

export const queryKeys: ReturnType<typeof createQueryKeys> = createQueryKeys(
  "platforms",
  {
    fetchPlatformsAccount: applicationId => [
      "fetchPlatformsAccount",
      applicationId,
    ],
    addPlatformsAccount: applicationId => [
      "addPlatformsAccount",
      applicationId,
    ],
    updatePlatformsAccount: applicationId => [
      "updatePlatformsAccount",
      applicationId,
    ],
    deletePlatformsAccount: applicationId => [
      "deletePlatformsAccount",
      applicationId,
    ],
    fetchMyPlatformsAccounts: storeId => ["fetchMyPlatformsAccount", storeId],
    fetchMyPlatformsAccount: storeId => ["fetchMyPlatformsAccount", storeId],
    addMyPlatformsAccount: applicationId => [
      "addMyPlatformsAccount",
      applicationId,
    ],
    updateMyPlatformsAccount: applicationId => [
      "updateMyPlatformsAccount",
      applicationId,
    ],
    fetchPlatformsAccountStatus: (storeId: number) => [
      "fetchPlatformsAccountStatus",
      storeId,
    ],
  } as const,
);

type PlatformsQueryKeys = ReturnType<typeof createQueryKeys>;
export const platformsQueryKeys: PlatformsQueryKeys = queryKeys;
