import React from "react";

export enum ButtonQty {
  SINGLE = "SINGLE",
  DUO_VERTICAL = "DUO_VERTICAL",
  DUO_HORIZONTAL = "DUO_HORIZONTAL",
}

export enum HeaderType {
  TEXT_ICON = "TEXT_ICON",
  HANDLEBAR_ONLY = "HANDLEBAR_ONLY",
}

export enum States {
  FULL = "FULL",
  FIT = "FIT",
}

export enum ButtonStates {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface BottomSheetProps {
  states?: States;
  headerType?: HeaderType;
  buttonQty?: ButtonQty;
  buttonStates?: ButtonStates;
  primaryButtonType?: string;
  secondaryButtonType?: string;
  hasBackground?: boolean;
  hasButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  headerText?: string;
  children?: React.ReactNode;
  onClose: () => void;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  prefixText?: string;
  onClickPrefixText?: () => void;
  isLoading?: boolean;
}
