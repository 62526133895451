import { css } from "styled-components";

const interfaceLight = css`
  &:hover {
    filter: brightness(96%);
    transition: all 0.2s ease-out;
  }

  &:active {
    filter: brightness(92%);
    scale: 96%;
    transition: all 0.2s ease-out;
  }
`;

const interfaceDark = css`
  &:hover {
    filter: brightness(92%);
    transition: all 0.2s ease-out;
  }

  &:active {
    filter: brightness(84%);
    scale: 96%;
    transition: all 0.2s ease-out;
  }
`;

const interfaceInverse = css`
  &:hover {
    filter: brightness(114%);
    transition: all 0.2s ease-out;
  }

  &:active {
    filter: brightness(124%);
    scale: 96%;
    transition: all 0.2s ease-out;
  }
`;

const interactionTransparentLight = css`
  &:hover {
    background-color: var(--earlybird-color-bg-hovered);
    transition: all 0.2s ease-out;
  }

  &:active {
    background-color: var(--earlybird-color-bg-pressed);
    scale: 96%;
    transition: all 0.2s ease-out;
  }
`;

const interactionTransparentDark = css`
  &:hover {
    background-color: var(--earlybird-color-bg-hovered-dark);
    transition: all 0.2s ease-out;
  }

  &:active {
    background-color: var(--earlybird-color-bg-pressed-dark);
    scale: 96%;
    transition: all 0.2s ease-out;
  }
`;

const interactionTransparentInverse = css`
  &:hover {
    background-color: var(--earlybird-color-bg-hovered-inverse);
    transition: all 0.2s ease-out;
  }

  &:active {
    background-color: var(--earlybird-color-bg-pressed-inverse);
    transition: all 0.2s ease-out;
    scale: 96%;
    transition: all 0.2s ease-out;
  }
`;

export {
  interactionTransparentDark,
  interactionTransparentInverse,
  interactionTransparentLight,
  interfaceDark,
  interfaceInverse,
  interfaceLight,
};
