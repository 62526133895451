import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
} from "date-fns";

/** 캘린더 날짜를 data 형태로 담아서 출력합니다.
 * 오래된 순으로 정렬합니다.
 */
export const generateMonths = (startDate: Date, monthsToShow: number) => {
  const months = [];
  let count = 0;
  for (let i = monthsToShow - 1; i >= 0; i--) {
    const monthStart = startOfMonth(addMonths(startDate, count));
    const monthEnd = endOfMonth(monthStart);
    const days = eachDayOfInterval({
      start: startOfWeek(monthStart),
      end: endOfWeek(monthEnd),
    });
    months.push({ monthStart, days, monthEnd });
    count = count - 1;
  }
  return months.reverse();
};

/** 캘린더 날짜의 Text 색상 값을 출력합니다. */
export const getTextColor = (
  selectedDates: Date[],
  date: Date,
  activeStartDate: Date,
) => {
  if (date > new Date()) {
    return "content-disabled";
  }

  if (date < activeStartDate) {
    return "content-disabled";
  }

  if (selectedDates.length === 1) {
    return isSameDay(selectedDates[0], date)
      ? "content-on-color"
      : "content-primary";
  } else if (selectedDates.length === 2) {
    const [start, end] = selectedDates;
    if (isSameDay(start, date) || isSameDay(end, date)) {
      return "content-on-color";
    } else if (isWithinInterval(date, { start, end })) {
      return "content-primary";
    }
  }
  return "content-primary";
};

export const checkSameMonth = (monthStart: Date, date: Date) => {
  return isSameMonth(date, monthStart);
};

export const checkSelectedDate = (value: Date[], date: Date) => {
  if (value.length === 1) {
    return isSameDay(value[0], date);
  } else if (value.length === 2) {
    const [start, end] = value;
    return isSameDay(start, date) || isSameDay(end, date);
  }
  return false;
};

/** 선택한 2개의 날짜 사이에 있는 기간들에 적용하는 background color 입니다. */
export const getSelectedRangeColor = (
  value: Date[],
  date: Date,
  direction: string,
) => {
  if (value.length === 1) {
    return "transparent";
  } else if (value.length === 2) {
    const [start, end] = value;

    if (isSameDay(start, date) && isSameDay(end, date)) {
      return "transparent";
    }
    if (isSameDay(start, date) && direction === "left") {
      return "#E7EFFF";
    }
    if (isSameDay(end, date) && direction === "right") {
      return "#E7EFFF";
    }

    if (
      !(isSameDay(start, date) || isSameDay(end, date)) &&
      isWithinInterval(date, { start, end })
    ) {
      return "#E7EFFF";
    }
  }
  return "transparent";
};
