import React, { Suspense, lazy } from "react";

import styled from "styled-components";

import { Box, PageContainer, Spinner, VStack } from "@earlybird/ui";

import { Meta } from "@components/commons";
import SettlementDatePicker from "@components/features/settlement/SettlementDatePicker";

import MainHeader from "@/components/layouts/MainHeader";
import useSettlement from "@/hooks/useSettlement";

const LazyVocEarlypayBoard = lazy(
  () => import("@components/features/feedback/VocEarlypayBoard"),
);
const LazyEarlySettlementBoard = lazy(
  () => import("@components/features/settlement/EarlySettlementBoard"),
);

export const Settlement: React.FC = () => {
  const { startDateParams, endDateParams } = useSettlement();

  return (
    <>
      <Meta
        title="얼리페이 | 정산내역"
        name={"정산내역"}
        options={{
          "Start Date": startDateParams,
          "End Date": endDateParams,
        }}
      />
      <MainHeader />
      <PageContainer
        padding={"20px 20px 73px 20px"}
        style={{ backgroundColor: "#F4F4F4" }}
      >
        <VStack>
          <InnerWrapper>
            {/** 정산 내역 날짜 조회 버튼 */}
            <SettlementDatePicker />

            {/** 선정산 요약 정보 */}
            <Suspense
              fallback={
                <Box width={"100%"} height={522} center>
                  <Spinner />
                </Box>
              }
            >
              <LazyEarlySettlementBoard />
            </Suspense>

            {/** 얼리페이 VOC 수집 */}
            <Suspense
              fallback={
                <Box width={"100%"} height={174} center>
                  <Spinner />
                </Box>
              }
            >
              <LazyVocEarlypayBoard category={"settlement"} />
            </Suspense>
          </InnerWrapper>
        </VStack>
      </PageContainer>
    </>
  );
};

export default Settlement;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  gap: 12px;
`;
