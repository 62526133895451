import { lazy } from "react";

import { History, Settlement } from "@pages/settlement";

const Billing = lazy(() => import("@pages/settlement/Billing"));
const Detail = lazy(() => import("@pages/settlement/Detail"));
const Elist = lazy(() => import("@pages/settlement/Detail/Elist"));

export const settlementRouters = [
  {
    index: true,
    Component: Settlement,
  },
  {
    path: "billing",
    children: [
      {
        index: true,
        Component: Billing,
      },
      {
        path: "detail",
        children: [
          {
            index: true,
            Component: Detail,
          },
          {
            path: "elist",
            Component: Elist,
          },
        ],
      },
    ],
  },
  {
    path: "history",
    Component: History,
  },
];
