import { debounce, trackEventMixpanel } from "@earlypay/shared";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ChevronRightIcon, InvoiceSolidIcon } from "@earlybird/icons";
import { Box, HStack, Icon, Spinner, Text, VStack } from "@earlybird/ui";

import { useFetchStoreDailySales } from "@/apis/hooks";

interface SaleStats {
  amountSales: number;
  numSales: number;
  date: string;
  perPlatforms: {
    amountSales: number;
    platform: "CARD" | "BAEMIN" | "YOGIYO" | "COUPANG" | "ETC";
    numSales: number;
    delta: {
      amount_sales: number;
      numSales: number;
    };
  }[];
}

// interface StoreDaily {
//   summary: {
//     amountSales: number;
//     numSales: number;
//   };
//   salesStats: SaleStats[];
// }

interface StoreStatusType {
  history: {
    amount: number;
    date: string;
    isDeposited: boolean;
  }[];
  latest: {
    results: {
      amount: number;
      date: string;
      isDeposited: boolean;
    }[];
    status: "CHECKING_SALES" | "WAITING_FOR_FIRST_DEPOSIT" | "SENT";
  };
  scrapingProgress: {
    baemin: boolean;
    card: boolean;
    coupang: boolean;
    yogiyo: boolean;
  };
}

const PreviousDateSales = ({
  storeId,
  storeStatus,
  storeStatusLoading,
}: {
  storeId: number;
  storeStatus: StoreStatusType;
  storeStatusLoading: boolean;
}) => {
  const navigate = useNavigate();

  // const isBeforeNoon = new Date().getHours() < 12;

  const findDate = ({ dayOffset }: { dayOffset: number }) => {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const paramDate = `${year}-${month}-${day}`;

    return paramDate;
  };

  const startDate = findDate({ dayOffset: -3 });
  const endDate = findDate({ dayOffset: -1 });
  const { data: storeSale, isLoading } = useFetchStoreDailySales(
    storeId,
    startDate,
    endDate,
  );

  const isDeliveryScraping =
    !storeStatus?.scrapingProgress.baemin ||
    !storeStatus?.scrapingProgress.yogiyo ||
    !storeStatus?.scrapingProgress.coupang;

  const isScrapping = isDeliveryScraping || !storeStatus?.scrapingProgress.card;

  const findDateSale = ({
    dayOffset,
    platform,
  }: {
    dayOffset: number;
    platform?: "CARD" | "BAEMIN" | "YOGIYO" | "COUPANG" | "ETC";
  }) => {
    const date = new Date();
    date.setDate(date.getDate() + dayOffset);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const paramDate = `${year}-${month}-${day}`;

    const findDate = storeSale?.salesStats?.find(
      (item: SaleStats) => item.date === paramDate,
    );

    const platformSale = findDate?.perPlatforms?.find(
      (item: SaleStats["perPlatforms"][number]) => item.platform === platform,
    );

    return {
      amountSales: findDate?.amountSales,
      platformSale,
      platformDelta: platformSale?.delta.numSales,
      month,
      day,
    };
  };

  const handleClickButton = debounce(
    async ({
      label,
      status,
      onClick,
    }: {
      label: string;
      status: string;
      onClick: () => void;
    }) => {
      onClick && onClick();
      trackEventMixpanel("click_button", {
        label: label,
        "revenue status": status,
      });
    },
    300,
  );

  if (isLoading || storeStatusLoading) return <LoadingSkeleton />;

  return (
    <Box
      radius={"xl"}
      width={"100%"}
      bg="bg-primary"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <DepositButton
        className="active"
        onClick={() =>
          handleClickButton({
            label: "어제 매출",
            status: isDeliveryScraping ? "수집 중" : "수집 후",
            onClick: () =>
              navigate(`/calendar?selectedDate=${findDate({ dayOffset: -1 })}`),
          })
        }
      >
        <VStack spacing={6}>
          <HStack justify={"between"} align={"center"}>
            <HStack spacing={2} width="auto">
              <Icon
                icon={InvoiceSolidIcon}
                color="content-primary"
                size={"md"}
              />
              <Text typo="body-2" weight="semi-bold" color="content-primary">
                어제 매출
              </Text>
            </HStack>
            <HStack align="center" width="auto">
              <Text
                typo="subtitle-2"
                weight="semi-bold"
                color="content-primary"
              >
                {isScrapping
                  ? "수집 중이에요"
                  : `${
                      Number(
                        findDateSale({ dayOffset: -1 })?.amountSales,
                      ).toLocaleString("ko-KR") ?? 0
                    }원`}
              </Text>
              {!isScrapping && (
                <Icon
                  icon={ChevronRightIcon}
                  color="content-quaternary"
                  size="md"
                />
              )}
            </HStack>
          </HStack>
          {!isScrapping && (
            <HStack justify="between">
              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    배달의민족
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.baemin
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "BAEMIN" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "BAEMIN" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.baemin}
                />
              </VStack>

              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    요기요
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.yogiyo
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "YOGIYO" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "YOGIYO" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.yogiyo}
                />
              </VStack>

              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    쿠팡이츠
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.coupang
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "COUPANG" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "COUPANG" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.coupang}
                />
              </VStack>

              <VStack align="center" spacing={4}>
                <VStack align="center">
                  <Text
                    color="content-tertiary"
                    typo="body-3"
                    weight="semi-bold"
                  >
                    카드
                  </Text>
                  <Text
                    color="content-secondary"
                    typo="body-1"
                    weight="semi-bold"
                  >
                    {!storeStatus?.scrapingProgress.card
                      ? "-"
                      : findDateSale({ dayOffset: -1, platform: "CARD" })
                          ?.platformSale?.numSales ?? 0}
                    건
                  </Text>
                </VStack>
                <CountDelta
                  deltaCount={
                    findDateSale({ dayOffset: -1, platform: "CARD" })
                      ?.platformDelta ?? 0
                  }
                  scrapping={storeStatus?.scrapingProgress.card}
                />
              </VStack>
            </HStack>
          )}
        </VStack>
      </DepositButton>
      <Divider />
      <Box width="100%" padding="4px 0">
        <VStack>
          <OtherDepositButton
            className="active"
            onClick={() =>
              handleClickButton({
                label: "그저께 매출",
                status: "수집 후",
                onClick: () =>
                  navigate(
                    `/calendar?selectedDate=${findDate({ dayOffset: -2 })}`,
                  ),
              })
            }
          >
            <HStack justify={"between"}>
              <Text typo="body-2" weight="regular" color="content-secondary">
                {`${findDateSale({ dayOffset: -2 }).month}월 ${
                  findDateSale({ dayOffset: -2 }).day
                }일`}{" "}
                매출
              </Text>
              <HStack align="center" width="auto">
                <Text typo="body-2" weight="regular" color="content-secondary">
                  {findDateSale({ dayOffset: -2 }).amountSales?.toLocaleString(
                    "ko-KR",
                  ) ?? 0}
                  원
                </Text>
                <Icon
                  icon={ChevronRightIcon}
                  color="content-quaternary"
                  size="sm"
                />
              </HStack>
            </HStack>
          </OtherDepositButton>
          <OtherDepositButton
            className="active"
            onClick={() =>
              handleClickButton({
                label: "삼일전 매출",
                status: "수집 후",
                onClick: () =>
                  navigate(
                    `/calendar?selectedDate=${findDate({ dayOffset: -3 })}`,
                  ),
              })
            }
          >
            <HStack justify={"between"}>
              <Text typo="body-2" weight="regular" color="content-secondary">
                {`${findDateSale({ dayOffset: -3 }).month}월 ${
                  findDateSale({ dayOffset: -3 }).day
                }일`}{" "}
                매출
              </Text>
              <HStack align="center" width="auto">
                <Text typo="body-2" weight="regular" color="content-secondary">
                  {findDateSale({ dayOffset: -3 }).amountSales?.toLocaleString(
                    "ko-KR",
                  ) ?? 0}
                  원
                </Text>
                <Icon
                  icon={ChevronRightIcon}
                  color="content-quaternary"
                  size="sm"
                />
              </HStack>
            </HStack>
          </OtherDepositButton>
        </VStack>
      </Box>
    </Box>
  );
};

const CountDelta = ({
  deltaCount,
  scrapping,
}: {
  deltaCount: number;
  scrapping: boolean;
}) => {
  const bgColor =
    scrapping === false
      ? "bg-positive-light"
      : deltaCount < 0
      ? "bg-informative-light"
      : "bg-negative-light";
  const textColor =
    scrapping === false
      ? "content-positive"
      : deltaCount < 0
      ? "content-highlight"
      : "content-negative";
  const textContent =
    scrapping === false
      ? "수집 중"
      : `${deltaCount > 0 ? `+${deltaCount}건` : `${deltaCount}건`}`;

  if (scrapping && deltaCount === 0) return null;
  return (
    <Box bg={bgColor} radius="md" padding="0 6px" display="flex">
      <Text color={textColor} typo="caption-1" weight="regular">
        {textContent}
        {/* {deltaCount > 0 && "+"}
        {deltaCount}건 */}
      </Text>
    </Box>
  );
};

export default PreviousDateSales;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eaeaea;
`;

const DepositButton = styled.div`
  padding: 16px;
  width: 100%;
  cursor: pointer;

  &:where(.active) {
    cursor: pointer;
    border-top-left-radius: var(--earlybird-radius-xl);
    border-top-right-radius: var(--earlybird-radius-xl);

    &:hover {
      background-color: var(--earlybird-color-bg-hovered);
      transition: all 0.2s ease-out;
    }

    &:active {
      background-color: var(--earlybird-color-bg-pressed);
      transition: all 0.2s ease-out;
    }
  }
`;

const OtherDepositButton = styled.div`
  padding: 8px 16px;
  width: 100%;
  cursor: pointer;

  &:where(.active) {
    cursor: pointer;

    &:hover {
      background-color: var(--earlybird-color-bg-hovered);
      transition: all 0.2s ease-out;
    }

    &:active {
      background-color: var(--earlybird-color-bg-pressed);
      transition: all 0.2s ease-out;
    }
  }
`;

const LoadingSkeleton = () => {
  return (
    <Box
      width="100%"
      radius={"xl"}
      bg="bg-primary"
      style={{ border: "1px solid #EAEAEA" }}
    >
      <Box padding="16px" width="100%">
        <HStack justify="between" align="center">
          <Skeleton width={87} height={28} />
          <Skeleton width={108} height={28} />
        </HStack>
      </Box>
      <Divider />
      <Box padding="8px 16px" width="100%">
        <VStack spacing={2}>
          <HStack justify="between" align="center">
            <Skeleton width={94} height={32} />
            <Skeleton width={44} height={32} />
          </HStack>
          <HStack justify="between" align="center">
            <Skeleton width={94} height={32} />
            <Skeleton width={44} height={32} />
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};
