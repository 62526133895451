import {
  getFormattedDateWithDash,
  getMonth,
  getStartAndEndOfMonth,
  getYear,
  getYesterday,
} from "@earlypay/shared";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { applicationState } from "@/recoil/application/atom";

export const useCalendar = () => {
  const application = useRecoilValue(applicationState);
  const location = useLocation();

  const newSearchParams = new URLSearchParams(location.search);
  const selectedDate = newSearchParams.get("selectedDate")
    ? new Date(newSearchParams.get("selectedDate"))
    : getYesterday();
  const currentMonth = newSearchParams.get("currentMonth")
    ? Number(newSearchParams.get("currentMonth"))
    : getMonth();
  const currentYear = newSearchParams.get("currentYear")
    ? Number(newSearchParams.get("currentYear"))
    : getYear();
  const currentDate = new Date(currentYear, currentMonth - 1, 1); // 현재 캘린더에서 보여주고 있는 년월
  const selectedPlatform = newSearchParams.get("platform") ?? "all";

  /**
   * 1. 회원가입일 이전의 날짜는 캘린더에서 선택할 수 없습니다.
   * 2. 오늘 기준으로 1년 내의 범위까지만 캘린더에서 선택할 수 있습니다.
   * 1과 2의 조건을 만족한 범위 내에서만 캘린더 날짜 선택이 가능합니다.
   */
  const today = new Date();
  const createdDate = new Date(application.user.created); // 유저 회원가입 날짜
  const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));
  const startDate = createdDate <= oneYearAgo ? oneYearAgo : createdDate;
  const endDate = getYesterday();

  const { startOfMonth, endOfMonth } = getStartAndEndOfMonth(currentDate);
  const startOfCalendar =
    currentYear === getYear(startDate) && currentMonth === getMonth(startDate)
      ? getFormattedDateWithDash(startDate)
      : startOfMonth;
  const endOfCalendar =
    currentYear === getYear(endDate) && currentMonth === getMonth(endDate)
      ? getFormattedDateWithDash(endDate)
      : endOfMonth;

  return {
    selectedDate,
    currentMonth,
    currentYear,
    startDate,
    startOfCalendar,
    endDate,
    endOfCalendar,
    selectedPlatform,
  };
};
