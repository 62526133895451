import {
  CalendarSolidIcon,
  HomeSolidIcon,
  UserSolidIcon,
  WonInCircleSolidIcon,
} from "@earlybird/icons";

export const validPaths = [
  "/",
  "/home",
  "/calendar",
  "/settlement",
  "/my",
  "/deposits",
];

export const bottomNavItems = [
  {
    label: "홈",
    paths: ["/", "/home"],
    icon: HomeSolidIcon,
  },
  {
    label: "캘린더",
    paths: ["/calendar"],
    icon: CalendarSolidIcon,
  },
  {
    label: "정산내역",
    paths: ["/settlement", "/deposits"],
    icon: WonInCircleSolidIcon,
  },
  {
    label: "마이페이지",
    paths: ["/my"],
    icon: UserSolidIcon,
  },
];
