import { useEffect, useState } from "react";

import { ACCUMULATED_AMOUNT_MONEY } from "@commons/constants";

import { Text } from "@earlybird/ui";

import Stack from "@components/atoms/Stack";

import { AccumulatedAmountWrapper, Title } from "./index.styled";

const AccumulatedAmountSection = () => {
  const [count, setCount] = useState(0);
  const start = 0;
  const end = ACCUMULATED_AMOUNT_MONEY;
  const duration = 3000;

  const frameRate = 3000 / 80;
  const totalFrame = Math.round(duration / frameRate);

  const easeOutExpo = (number: number) => {
    return number === 1 ? 1 : 1 - Math.pow(2, -10 * number);
  };

  useEffect(() => {
    let currentNumber = start;
    const counter = setInterval(() => {
      const progress = easeOutExpo(++currentNumber / totalFrame);
      if (end) setCount(Math.round(end * progress));

      if (progress === 1) {
        clearInterval(counter);
      }
    }, frameRate);
  }, [end, frameRate, start, totalFrame]);

  return (
    <AccumulatedAmountWrapper>
      <Stack center>
        <Title center className="accumulated-amount-title-mobile">
          {"지금까지 얼리페이로\n지급된 누적 금액"}
        </Title>
        <Title center className="accumulated-amount-title">
          {"지금까지 얼리페이로 지급된 누적 금액"}
        </Title>
        <Title center color={"content-highlight"}>
          {count > 2000 ? (count - 2000 < 100 ? "2,0" : "2,") : ""}
          {count > 2000 ? count - 2000 : count}억 원*
        </Title>
      </Stack>

      <Text
        className={"accumulated-amount-text"}
        color={"content-secondary"}
        center
        as="h4"
      >
        많은 사장님들이 얼리페이를 통해
        <br />
        유동성 있는 자금 관리를 하고 있습니다.
      </Text>
      <Text
        className="accumulated-amount-caption"
        typo={"body-3"}
        color={"content-tertiary"}
      >
        *2024년 8월 기준
      </Text>
    </AccumulatedAmountWrapper>
  );
};

export default AccumulatedAmountSection;
