import { NotificationRequests } from "@earlypay/shared";

import { instance } from "@apis/instance";

/**
 * 유저의 선정산 시작 여부를 확인하는 API 함수입니다.
 */
export const getCheckedEarlypaid = async (storeId: number) => {
  const { data } = await instance({
    method: "get",
    url: `/v1/stores/${storeId}/check-earlypaid`,
  });

  return data.earlypaid;
};

/**
 * 유저의 카드 연동 상태 정보를 가져오는 함수입니다.
 */
export const getCardConnect = async () => {
  const { data } = await instance({
    method: "get",
    url: `/v1/stores/card-connect`,
  });

  return data.results;
};

/**
 * 다-가게를 포함하는 함수입니다.
 */
export const getStoreInfo = async (storeId: number) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/info`,
  });

  return data;
};

/**
 * 가게 상태값 함수입니다.
 */
export const getStoreStatus = async (
  storeId: number,
  startDate?: string,
  endDate?: string,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/earlypay/status?start_date=${startDate}&end_date=${endDate}`,
  });

  return data.earlypay;
};

/**
 * 가게 일별 매출을 가져오는 함수입니다.
 */
export const getStoreDailySales = async (
  storeId: number,
  startDate?: string,
  endDate?: string,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/stats/sales-daily?start_date=${startDate}&end_date=${endDate}`,
  });

  return data;
};

/**
 * 가게 시간별 매출을 가져오는 함수입니다.
 */
export const getStoreHourlySales = async (
  storeId: number,
  startDate: string,
  endDate: string,
  granularity?: string,
) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/stats/sales-hourly?start_date=${startDate}&end_date=${endDate}&granularity_hours=${granularity}`,
  });

  return data;
};

/**
 * 가게 알림 설정 정보를 가져오는 함수입니다.
 */
export const getNotification = async (storeId: number) => {
  const { data } = await instance({
    method: "get",
    url: `/v2/stores/${storeId}/preferences/notification`,
  });

  return data;
};

/**
 * 가게 알림 설정 정보를 업데이트하는 함수입니다.
 */
export const patchNotification = async <T>(
  payload: NotificationRequests,
  storeId: number,
): Promise<T | null> => {
  const { data } = await instance({
    method: "patch",
    url: `/v2/stores/${storeId}/preferences/notification`,
    data: { ...payload },
  });

  return data;
};
