import { BoxButton, Image, Text } from "@earlybird/ui";

import Stack from "@components/atoms/Stack";

import {
  CustomButtonWrapper,
  MainContentStack,
  MainSectionImage,
  MainSectionImageMobile,
  MainSectionWrapper,
  MainStack,
  MobileTitleStack,
  Title,
  TitleStack,
} from "./index.styled";

export interface MainSectionProps {
  handleClickTrialButton: (title?: string, position?: string) => void;
}

const MainSection = (props: MainSectionProps) => {
  return (
    <MainSectionWrapper>
      <MainStack>
        <MainContentStack>
          <TitleStack>
            <Title>사장님!</Title>
            <Stack direction="row">
              <Title>어제 번 돈 </Title>
              <Title color="content-highlight">얼리페이</Title>
              <Title>에서</Title>
            </Stack>
            <Title>오늘 받으세요</Title>
          </TitleStack>

          <MobileTitleStack center>
            <Title>사장님! 어제 번 돈 </Title>
            <Stack direction="row">
              <Title color="content-highlight">얼리페이</Title>
              <Title>에서</Title>
            </Stack>
            <Title>오늘 받으세요</Title>
          </MobileTitleStack>

          <MainSectionImageMobile>
            <Image
              src={"/service/delivery-card-money.png"}
              width={256}
              height={256}
              alt="얼리페이는 배달앱 3사(배달의민족, 요기요, 쿠팡이츠)와 카드사에 묶인 매장 매출을 바로 다음날 선정산 받을 수 있습니다."
            />
          </MainSectionImageMobile>
          <Text
            className="earlypay-landing-content-text"
            color="content-secondary"
            as="h4"
          >
            어제 매출, 꼭 정산일까지 기다려야 할까요?
            <br />
            얼리페이는 배달앱 3사(배달의민족, 요기요, 쿠팡이츠)와
            <br />
            카드사에 묶인 어제 매출을 평일에도 휴일에도
            <br />
            받을 수 있습니다.
          </Text>
          <CustomButtonWrapper>
            <BoxButton
              onClick={() =>
                props.handleClickTrialButton("한 달 무료 이용하기", "메인 섹션")
              }
              size={"sm"}
            >
              한 달 무료 이용하기
            </BoxButton>
          </CustomButtonWrapper>
        </MainContentStack>
        <MainSectionImage>
          <Image
            src={"/service/delivery-card-money.png"}
            width={360}
            height={360}
            preload
            alt="얼리페이는 배달앱 3사(배달의민족, 요기요, 쿠팡이츠)와 카드사에 묶인 매장 매출을 바로 다음날 선정산 받을 수 있습니다."
          />
        </MainSectionImage>
      </MainStack>
    </MainSectionWrapper>
  );
};

export default MainSection;
