import React from "react";

import { fadeInMotion, slideUpModalMotion } from "@styles/animation";
import styled from "styled-components";

import { XIcon } from "@earlybird/icons";
import { BoxButton, IconButton, Text } from "@earlybird/ui";

import Interaction from "@components/atoms/Interaction";
import {
  BottomSheetProps,
  ButtonQty,
  ButtonStates,
  HeaderType,
  States,
} from "@components/molecules/BottomSheet/type";

import { ColorsTypes } from "@/@types/ThemesType";

export const BottomSheet: React.FC<BottomSheetProps> = ({
  states = States.FIT,
  headerType = HeaderType.HANDLEBAR_ONLY,
  buttonQty = ButtonQty.SINGLE,
  buttonStates = ButtonStates.ACTIVE,
  primaryButtonType = "primary",
  secondaryButtonType = "tertiary",
  hasBackground = true,
  hasButton = true,
  prefixText,
  onClickPrefixText,
  primaryButtonText,
  secondaryButtonText,
  headerText,
  children,
  onClose,
  onClickPrimaryButton,
  onClickSecondaryButton,
  isLoading,
}) => {
  const primaryButtonTag = primaryButtonType;
  const secondaryButtonTag =
    buttonQty === ButtonQty.DUO_VERTICAL ? "ghost" : secondaryButtonType;
  const isDisabled = buttonStates === ButtonStates.INACTIVE;

  /** PrimaryButton 을 클릭하였을 경우 호출하는 이벤트입니다.
   *  클릭 함수가 없을 경우, 바텀시트를 종료하는 이벤트를 호출합니다.
   */
  const handleClickPrimaryButton = () => {
    onClickPrimaryButton ? onClickPrimaryButton() : onClose();
  };

  /** SecondaryButton 을 클릭하였을 경우 호출하는 이벤트입니다.
   *  클릭 함수가 없을 경우, 바텀시트를 종료하는 이벤트를 호출합니다.
   */
  const handleClickSecondaryButton = () => {
    onClickSecondaryButton ? onClickSecondaryButton() : onClose();
  };

  return (
    <>
      <Background onClick={onClose} hasBackground={hasBackground} />
      <Wrapper states={states}>
        <InnerWrapper>
          {/** headerType 에 따라 헤더바를 보여줍니다. */}
          {headerType === HeaderType.HANDLEBAR_ONLY && <HeaderBar />}
          <ContentsWrapper>
            {headerType === HeaderType.HANDLEBAR_ONLY && (
              <HeaderBarWrapper>
                <Text bold typo={"subtitle-2"}>
                  {headerText}
                </Text>
                {prefixText && (
                  <Interaction>
                    <PrefixTextButton onClick={onClickPrefixText}>
                      <Text color={ColorsTypes.CONTENT_HIGHLIGHT}>
                        {prefixText}
                      </Text>
                    </PrefixTextButton>
                  </Interaction>
                )}
              </HeaderBarWrapper>
            )}
            {/** headerType 에 따라 타이틀+아이콘 영역을 보여줍니다. */}
            {headerType === HeaderType.TEXT_ICON && (
              <HeaderWrapper>
                <Text bold typo={"subtitle-2"}>
                  {headerText}
                </Text>
                <IconButton icon={XIcon} onClick={onClose} />
              </HeaderWrapper>
            )}

            {/** 콘텐츠 영역 */}
            {children}
          </ContentsWrapper>
        </InnerWrapper>

        {/** 버튼 영역 */}
        {hasButton && (
          <ButtonWrapper buttonQty={buttonQty}>
            {buttonQty !== ButtonQty.SINGLE && (
              <SecondaryButton
                onClick={handleClickSecondaryButton}
                hierarchy={secondaryButtonTag}
                disabled={isDisabled}
                loading={isLoading}
                size={"lg"}
              >
                {secondaryButtonText}
              </SecondaryButton>
            )}
            <PrimaryButton
              onClick={handleClickPrimaryButton}
              hierarchy={primaryButtonTag}
              disabled={isDisabled}
              loading={isLoading}
              size={"lg"}
            >
              {primaryButtonText}
            </PrimaryButton>
          </ButtonWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default BottomSheet;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ hasBackground }: { hasBackground: boolean }) =>
    hasBackground ? "rgba(1, 1, 1, 0.5)" : "rgba(1, 1, 1, 0)"};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 80;

  ${fadeInMotion};
`;

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${({ states }: { states: States }) =>
    states === States.FULL ? "calc(100vh - 46px)" : "fit-content"};
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  border-radius: 12px 12px 0 0;
  background: #fefefe;
  z-index: 90;

  ${slideUpModalMotion};
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 8px;
  flex-direction: ${({ buttonQty }: { buttonQty: ButtonQty }) =>
    buttonQty === ButtonQty.DUO_HORIZONTAL ? "row" : "column-reverse"};
`;

const PrimaryButton = styled(BoxButton)``;

const SecondaryButton = styled(BoxButton)``;

const HeaderBar = styled.div`
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background: #c6c6c6;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderBarWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
`;

const PrefixTextButton = styled.button`
  background: transparent;
`;
