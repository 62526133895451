import React, { MouseEvent } from "react";

import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Icon, Text } from "@earlybird/ui";

import { bottomNavItems, validPaths } from "./bottomNavData";

export const BottomNav: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const normalizedPath =
    pathname.endsWith("/") && pathname.length > 1
      ? pathname.slice(0, -1)
      : pathname;
  const isValidPath = validPaths.includes(normalizedPath);

  /** 상세 페이지로 들어갈 경우, BottomNav 를 보여주지 않습니다. */
  if (!isValidPath) {
    return;
  }

  /** 같은 페이지에서 NavItem 을 클릭했을 경우, 페이지 이동을 막습니다. */
  const handleClick = (
    e: MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string,
  ) => {
    if (location.pathname === path) {
      e.preventDefault();
    }
  };

  return (
    <Wrapper>
      <BottomTabBox>
        {bottomNavItems.map((item, index) => {
          const isActive = item.paths.includes(normalizedPath);
          const isHome =
            item.paths.includes("/") || item.paths.includes("/home");
          const to = isHome ? "/" : `${item.paths[0]}`;

          return (
            <NavItem
              to={to}
              onClick={(e: MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                handleClick(e, to)
              }
              key={index}
            >
              <CustomIcon
                size={"sm"}
                icon={item.icon}
                color={isActive ? "content-primary" : "content-disabled"}
              />
              <Text
                typo={"caption-1"}
                bold
                color={isActive ? "content-primary" : "content-disabled"}
              >
                {item.label}
              </Text>
            </NavItem>
          );
        })}
      </BottomTabBox>
    </Wrapper>
  );
};

export default BottomNav;

const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;
`;

const BottomTabBox = styled.div`
  position: fixed;
  bottom: 0;
  background: #fefefe;
  height: 57px;
  max-width: 498px;
  width: 100%;
  border-radius: 15px 15px 0 0;
  border-top: 1px solid #eaeaea;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const CustomIcon = styled(Icon)`
  transition: color 0.3s ease-in-out, color 0.3s ease-in-out;
`;
