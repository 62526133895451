import { createQueryKeys } from "@lukemorales/query-key-factory";

const queryKeys: ReturnType<typeof createQueryKeys> = createQueryKeys(
  "applications",
  {
    fetchApplication: () => ["fetchApplication"],
    updateIdentification: (applicationId: number) => [
      "updateIdentification",
      applicationId,
    ],
    updateBusinessLicense: (applicationId: number) => [
      "updateBusinessLicense",
      applicationId,
    ],
    updateApplication: () => ["updateApplication"],
  } as const,
);

type ApplicationQueryKeys = ReturnType<typeof createQueryKeys>;
export const applicationsQueryKeys: ApplicationQueryKeys = queryKeys;
