import themes from "@styles/themes";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

import Stack from "@components/atoms/Stack";

export const MainSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  background-color: ${themes.colors.BG_PRIMARY};

  @media screen and (max-width: 1279px) {
    padding: 64px 16px;

    .earlypay-landing-content-text {
      @media screen and (max-width: 1279px) {
        text-align: center !important;
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }
`;

export const TitleStack = styled(Stack)`
  @media screen and (max-width: 1279px) {
    display: none;
  }
`;

export const MobileTitleStack = styled(Stack)`
  @media screen and (min-width: 1280px) {
    display: none;
  }
`;

export const Title = styled(Text).attrs({
  typo: "title-1",
  bold: true,
})`
  white-space: pre;

  @media screen and (max-width: 1279px) {
    font-size: 32px !important;
    line-height: 38px !important;
  }
`;

export const MainContentStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 360px;
  gap: 16px;

  @media screen and (max-width: 1279px) {
    width: 100%;
    align-items: center;
  }

  .button {
    margin-top: 16px;
  }
`;

export const MainStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
`;

export const MainSectionImage = styled.div`
  @media screen and (max-width: 1279px) {
    display: none !important;
  }
`;

export const MainSectionImageMobile = styled.div`
  @media screen and (min-width: 1279px) {
    display: none;
  }
`;

export const CustomButtonWrapper = styled.div`
  margin-top: 16px;

  @media screen and (max-width: 1279px) {
    display: none;
  }
`;
