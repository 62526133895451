import React from "react";

import {
  AdditionalDepositCode,
  AdditionalDepositDetailNames,
  DepositorNames,
} from "@earlypay/shared/typings";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Text } from "@earlybird/ui";

import AdditionalDepositDetailAmountList from "@components/features/settlement/SettlementAmountList/AdditionalDepositDetailAmountList";
import { Header, Meta } from "@components/layouts";

import useSettlement from "@hooks/useSettlement";

import { useFetchAdditionalDepositDetail } from "@apis/hooks";

import { changeNumberToMoney } from "@utils/types";

import { changeDepositorToCode } from "../../utils";

/** 그 외 추가입금 상세내역에서 "기타 추가입금" 또는 기타 "추가선정산 내역"을 보여주는 페이지입니다. */
export const Elist: React.FC = () => {
  const navigate = useNavigate();
  const {
    startDateParams,
    endDateParams,
    targetParams,
    typeParams,
    depositorParams,
    prevPath,
  } = useSettlement();
  const depositor = changeDepositorToCode(depositorParams);
  const type =
    typeParams === "additional-deposit"
      ? AdditionalDepositCode.ETC
      : AdditionalDepositCode.ADDITIONAL_EARLYPAY;

  /** 선정산 기타 추가입금 상세내역 API 를 호출합니다. */
  const { data, isLoading } = useFetchAdditionalDepositDetail(
    {
      startDate: startDateParams,
      endDate: endDateParams,
    },
    targetParams,
  );
  const totalAmounts =
    (type === AdditionalDepositCode.ETC
      ? data?.totalDeposit
      : data?.totalEarlypay) || 0;
  const list =
    (type === AdditionalDepositCode.ETC
      ? data?.depositList
      : data?.earlypayList) || [];
  const title = AdditionalDepositDetailNames[type];

  const handleGoToBack = () => {
    if (!prevPath) {
      return navigate(
        `/settlement/billing/detail?startDate=${startDateParams}&endDate=${endDateParams}&depositor=${depositorParams}&type=${typeParams}`,
      );
    }

    return navigate(prevPath);
  };

  return (
    <Container>
      <Meta
        title={`얼리페이 | 정산내역`}
        name={
          AdditionalDepositCode.ETC
            ? "추가 입금 상세 내역"
            : "추가 선정산 상세 내역"
        }
        options={{
          "Start Date": startDateParams,
          "End Date": endDateParams,
          Depositor: DepositorNames[depositor],
          Type: typeParams,
          Title: title,
        }}
      />
      <Header handleClickLeftButton={handleGoToBack} />
      <TitleWrapper>
        <Text color={"content-tertiary"} bold>
          {title}
        </Text>
        <Text typo={"title-3"} bold>
          {isLoading ? (
            <Skeleton height={28} width={120} />
          ) : (
            changeNumberToMoney(totalAmounts)
          )}
        </Text>
      </TitleWrapper>
      <Divider />
      <Wrapper>
        <AdditionalDepositDetailAmountList
          date={targetParams}
          list={list}
          isLoading={isLoading}
        />
      </Wrapper>
    </Container>
  );
};

export default Elist;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow: scroll;
  cursor: default;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  background: white;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 16px;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
  flex-shrink: 0;
`;
